import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { Dropdown, Option, tokens, OptionGroup } from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'
import { CheckboxChecked24Filled } from '@fluentui/react-icons'
import { Field } from './Field'
type DropdownMultiProps = {
   name: any
   control: Control<any>
   label: string
   items: any
   parentItemId: string
   parentItemType: string
   modelName: string
   disabled?: boolean
   disabledOption?: string
   required?: boolean
}
export const DropdownMulti = ({
   name,
   control,
   label,
   items,
   parentItemId,
   parentItemType,
   modelName,
   disabled,
   disabledOption,
   required,
}: DropdownMultiProps): JSX.Element => {
   const { t } = useTranslation()
   const handleSelectAll = (selectedOptions: string[], onChange: Function) => {
      if (selectedOptions.length < items.length) {
         onChange(
            items.map((item: any) => ({
               [modelName + 'Id']: item.id,
               [`${parentItemType}Id`]: parentItemId,
               [modelName]: item,
            }))
         )
      } else {
         onChange([])
      }
   }
   return (
      <Controller
         name={name}
         control={control}
         rules={{ required: required ? t('RequiredField') : false }}
         render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
               <Field label={label} required={required ?? false} validationMessage={error ? error.message : null} validationState={error ? 'error' : null}>
                  <Dropdown
                     multiselect
                     onOptionSelect={(e, data) => {
                        if (data.selectedOptions.includes('all')) {
                           handleSelectAll(data.selectedOptions, onChange)
                        } else {
                           onChange(
                              data.selectedOptions.map((option) => ({
                                 [modelName + 'Id']: option,
                                 [`${parentItemType}Id`]: parentItemId,
                                 [modelName]: items.find((x: any) => x.id === option),
                              }))
                           )
                        }
                     }}
                     selectedOptions={
                        value ? value.sort((a: any, b: any) => a[modelName].text.localeCompare(b[modelName].text)).map((x: any) => x?.[modelName + 'Id']) : []
                     }
                     style={{ minWidth: 'auto' }}
                     value={
                        value && value.length === items.length
                           ? t('All')
                           : value && value.length < items.length
                           ? value.map((x: any) => x[modelName]?.text).join(', ')
                           : ''
                     }
                     disabled={disabled}
                  >
                     <OptionGroup>
                        <Option text={t('All')} key="all" value="all" style={{ border: 'none' }} checkIcon={value.length === items.length && null}>
                           {value.length === items.length && <CheckboxChecked24Filled color={tokens.colorBrandForeground1} />}
                           {t('All')}
                        </Option>
                     </OptionGroup>
                     {items
                        .sort((x: any, b: any) => x.text.localeCompare(b.text))
                        .map((x: any) => (
                           <Option key={x.id} value={x.id} disabled={x.text === disabledOption}>
                              {x.text}
                           </Option>
                        ))}
                  </Dropdown>
               </Field>
            )
         }}
      />
   )
}
