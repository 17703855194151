import { TableColumnDefinition } from '@fluentui/react-components'
import { t } from 'i18next'
import { Risk, RiskAnalysis } from '../api/schemas/schema'
import { IDataContext } from '../interfaces/IDataContext'
import { api } from './apiHelper'
import { handleMessage } from './stateHelper'
import { IRiskAnalysisState } from '../interfaces/IRiskAnalysisState'

export const addRisk = async (
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IRiskAnalysisState>>,
   close: () => void,
   dataContext: IDataContext,
   data: Risk,
   parent: RiskAnalysis
) => {
   try {
      const { accessToken, userAccount } = dataContext.state
      data.riskAnalysisId = parent.id
      data.modifiedBy = userAccount
      data.modifiedById = userAccount.id
      data.createdBy = userAccount
      data.createdById = userAccount.id

      const addedRisk = (await api(accessToken).addRisk(data)).data
      setRiskAnalysisState((prev) => ({ ...prev, item: { ...prev.item, risks: prev.item.risks ? [...prev.item.risks, addedRisk] : [addedRisk] } }))
      handleMessage(dataContext.setRootState, 'success', t('ItemSuccessfullySaved', { Item: t('Risk') }))
   } catch (error) {
      handleMessage(dataContext.setRootState, 'error', t('CouldNotCreateItem', { Item: t('Risk') }))
   } finally {
      close()
   }
}

export const getFRARiskIdentifyingColumns = (): TableColumnDefinition<Risk>[] => [
   {
      columnId: 'scenario',
      renderHeaderCell: () => t('RiskScenario'),
      renderCell: (item) => item.scenario,
      compare: (a, b) => a.scenario.localeCompare(b.scenario),
   },
   {
      columnId: 'source',
      renderHeaderCell: () => t('RiskSource'),
      renderCell: (item) => item.source,
      compare: (a, b) => a.source.localeCompare(b.source),
   },
   {
      columnId: 'protectedAsset',
      renderHeaderCell: () => t('ProtectedAsset'),
      renderCell: (item) => item.protectedAsset.name,
      compare: (a, b) => a.source.localeCompare(b.source),
   },
]
