import { TableColumnDefinition } from '@fluentui/react-components'
import { TFunction, t } from 'i18next'
import { RiskAnalysis } from '../api/schemas/schema'
import { IDataContext } from '../interfaces/IDataContext'
import { api } from './apiHelper'
import { handleMessage } from './stateHelper'
import { UseFormReset } from 'react-hook-form'
import { IRiskManagementState } from '../interfaces/IRiskManagementState'
import { getRiskAnalysisTypeString } from './enumHelper'
import dayjs from 'dayjs'
import { Avatar } from '../components/common/avatar/Avatar'

export enum RiskManagementTabs {
   Ongoing = 'ongoing',
   Concluded = 'concluded',
}

export const getRiskManagementColumns = (t: TFunction<'translation', undefined>): TableColumnDefinition<RiskAnalysis>[] => [
   {
      columnId: 'name',
      renderHeaderCell: () => t('AnalysisName'),
      renderCell: (item) => item.name,
      compare: (a, b) => a.name.localeCompare(b.name),
   },
   {
      columnId: 'type',
      renderHeaderCell: () => t('Type'),
      renderCell: (item) => getRiskAnalysisTypeString(item.type),
      compare: (a, b) => getRiskAnalysisTypeString(a.type).localeCompare(getRiskAnalysisTypeString(b.type)),
   },
   {
      columnId: 'name',
      renderHeaderCell: () => t('ModifiedBy'),
      renderCell: (item) => <Avatar name={item.modifiedBy?.title} />,
      compare: (a, b) => a.modifiedBy?.title.localeCompare(b.modifiedBy?.title ?? ''),
   },
   {
      columnId: 'name',
      renderHeaderCell: () => t('Modified'),
      renderCell: (item) => {
         return dayjs(item.modified).format('YYYY-MM-DD HH:mm')
      },
      compare: (a, b) => new Date(a.modified).getHours() - new Date(b.modified).getHours(),
   },
]

export const addRiskAnalysis = async (
   setRiskManagementState: React.Dispatch<React.SetStateAction<IRiskManagementState>>,
   setError: React.Dispatch<React.SetStateAction<string>>,
   reset: UseFormReset<RiskAnalysis>,
   dataContext: IDataContext,
   data: RiskAnalysis
) => {
   try {
      const { userAccount, accessToken, organisation } = dataContext.state
      data.organisationId = organisation.id
      data.modifiedById = userAccount.id
      data.modifiedBy = userAccount
      data.createdBy = userAccount
      data.createdById = userAccount.id
      const response = await api(accessToken).addRiskAnalysis(data)
      const addedAnalysis = response.data
      reset(addedAnalysis, { keepIsSubmitSuccessful: true, keepIsSubmitted: true })
      setRiskManagementState((prev) => ({ ...prev, allItems: [...prev.allItems, addedAnalysis], open: false }))
      handleMessage(dataContext.setRootState, 'success', t('ItemSuccessfullySaved', { Item: t('RiskAnalysis') }))
   } catch (error: any) {
      setError(error.error.Message)
      reset()
      setRiskManagementState((prev) => ({ ...prev, open: false }))
      handleMessage(dataContext.setRootState, 'error', t('CouldNotCreateItem', { Item: t('RiskAnalysis') }))
   }
}
