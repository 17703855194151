import React from 'react'
import { Subtitle1, Subtitle2, Title3, makeStyles, mergeClasses, tokens } from '@fluentui/react-components'

type TitleProps = {
   as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
   children: string
   className?: string
   weight?: 'bold' | 'regular' | 'semibold'
   noMargin?: boolean
}
const useStyles = makeStyles({
   bold: {
      fontWeight: tokens.fontWeightBold,
   },
   regular: {
      fontWeight: tokens.fontWeightRegular,
   },
   noMargin: {
      marginTop: 0,
      marginBottom: 0,
   },
})
export const Title = ({ as, children, className, weight, noMargin }: TitleProps): JSX.Element => {
   const classes = useStyles()
   switch (as) {
      case 'h1':
         return (
            <Title3
               className={mergeClasses(
                  weight === 'bold' ? tokens.fontWeightBold : weight === 'regular' ? classes.regular : '',
                  noMargin ? classes.noMargin : '',
                  className ?? ''
               )}
               as={as}
            >
               {children}
            </Title3>
         )
      case 'h2':
         return (
            <Subtitle1
               className={mergeClasses(
                  weight === 'bold' ? tokens.fontWeightBold : weight === 'regular' ? classes.regular : '',
                  noMargin ? classes.noMargin : '',
                  className ?? ''
               )}
               as={as}
            >
               {children}
            </Subtitle1>
         )
      case 'h3':
         return (
            <Subtitle1
               className={mergeClasses(
                  weight === 'bold' ? tokens.fontWeightBold : weight === 'regular' ? classes.regular : '',
                  noMargin ? classes.noMargin : '',
                  className ?? ''
               )}
               as={as}
            >
               {children}
            </Subtitle1>
         )
      case 'h4':
         return (
            <Subtitle2
               as={as}
               className={mergeClasses(
                  weight === 'bold' ? tokens.fontWeightBold : weight === 'regular' ? classes.regular : '',
                  noMargin ? classes.noMargin : '',
                  className ?? ''
               )}
            >
               {children}
            </Subtitle2>
         )
      case 'h5':
      default:
         return (
            <Subtitle2
               as={as}
               className={mergeClasses(
                  weight === 'bold' ? tokens.fontWeightBold : weight === 'regular' ? classes.regular : '',
                  noMargin ? classes.noMargin : '',
                  className ?? ''
               )}
            >
               {children}
            </Subtitle2>
         )
   }
}
