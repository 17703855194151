import React, { useEffect } from 'react'
import { ActionPlanInfo } from './ActionPlanInfo'
import { ActionPlan } from '../../../../api/schemas/schema'
import { Checkbox, Divider } from '@fluentui/react-components'
import { GridItem } from '../../../common/grid/GridItem'
import { Controller, UseFormReturn } from 'react-hook-form'
import { ControlledTextInput } from '../../../common/inputs/TextInput'
import { useTranslation } from 'react-i18next'

type CompleteActionProps = {
   actionPlanForm: UseFormReturn<ActionPlan, any, undefined>
   actionPlan: ActionPlan
}
export const CompleteAction = ({ actionPlanForm, actionPlan }: CompleteActionProps) => {
   const { control, setValue, watch, reset } = actionPlanForm
   const { t } = useTranslation()
   useEffect(() => {
      reset((prev) => ({ ...prev, actionTaken: true }))
   }, [])
   return (
      <>
         <ActionPlanInfo actionPlan={actionPlan} />
         <GridItem size="12/12">
            <Divider appearance="brand" />
         </GridItem>
         <GridItem size="12/12">
            <Controller
               control={control}
               name="actionTaken"
               render={({ field: { value, onChange } }) => (
                  <Checkbox
                     size="large"
                     label={t('NoActionsTaken')}
                     checked={value === false}
                     onChange={(e, data) => {
                        const actionTaken = !value
                        if (!actionTaken) {
                           setValue('implementedAction', null)
                           setValue('implementedById', null)
                           setValue('implementedBy', null)
                        }
                        onChange(actionTaken)
                     }}
                  />
               )}
            />
         </GridItem>
         {watch('actionTaken') ? (
            <GridItem size="12/12">
               <ControlledTextInput control={control} name={'implementedAction'} required label={t('ImplementedActions')} multiline />
            </GridItem>
         ) : null}
         <GridItem size="12/12">
            <ControlledTextInput control={control} name={'implementedActionComment'} label={t('Comment')} multiline />
         </GridItem>
      </>
   )
}
