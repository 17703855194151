import { Button, Dialog, DialogContent, DialogTitle, makeStyles } from '@fluentui/react-components'
import React, { useContext, useEffect, useState } from 'react'
import { ModalActions, DialogSurface, DialogBody } from '../../common/modal/Modal'
import { useTranslation } from 'react-i18next'
import { ActionPlan, ActionPlanStatus, AnswerOptionAnalysisQuestion, DemandAnalysis, DemandAnalysisQuestion } from '../../../api/schemas/schema'
import { useForm } from 'react-hook-form'
import { Grid } from '../../common/grid/Grid'
import { GridItem } from '../../common/grid/GridItem'
import { DataContext } from '../../../context/DataContext'
import { getActionModalBtnText, getActionModalContent, getActionModalTitle, submitActionPlan } from '../../../helpers/actionPlanHelper'
import { ConnectedDemandPopover } from '../ConnectedDemandPopover'

type ModalActionPlanProps = {
   selectedItem: ActionPlan
   parent: DemandAnalysisQuestion
   close: () => void
   setAnalysisState: React.Dispatch<
      React.SetStateAction<{
         loading: boolean
         error: Error
         item: DemandAnalysis
      }>
   >
}
const useStyles = makeStyles({
   dialogSurface: {
      maxWidth: '800px',
   },
})
export const ModalActionPlan = ({ selectedItem, parent, close, setAnalysisState }: ModalActionPlanProps) => {
   const dataContext = useContext(DataContext)
   const { t } = useTranslation()
   const classes = useStyles()
   const actionPlanForm = useForm<ActionPlan>({ defaultValues: { actionNeeded: true } })
   const { reset, handleSubmit } = actionPlanForm
   const [updatedAnswer, setUpdatedAnswer] = useState<AnswerOptionAnalysisQuestion>(null)
   useEffect(() => {
      if (selectedItem)
         if (selectedItem.status === ActionPlanStatus.Started) reset({ ...selectedItem, actionTaken: true })
         else reset(selectedItem)

      setUpdatedAnswer(parent && parent.updatedAnswer ? parent.updatedAnswer : null)
   }, [selectedItem])

   const onSubmit = (data: ActionPlan) => {
      submitActionPlan(data, dataContext, close, setAnalysisState, reset, parent, updatedAnswer)
   }
   const isEstablished = selectedItem && selectedItem.status === ActionPlanStatus.Established
   return (
      <Dialog open={selectedItem ? true : false}>
         {selectedItem ? (
            <DialogSurface className={classes.dialogSurface}>
               <DialogBody>
                  <DialogTitle action={selectedItem.status === ActionPlanStatus.Completed ? <ConnectedDemandPopover item={parent} /> : null}>
                     {getActionModalTitle(selectedItem)}
                  </DialogTitle>
                  <DialogContent>
                     <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid>
                           {getActionModalContent(selectedItem, actionPlanForm, parent, setUpdatedAnswer, updatedAnswer)}
                           <GridItem size="12/12">
                              <ModalActions>
                                 <Button
                                    onClick={() => {
                                       reset({ actionNeeded: true })
                                       close()
                                    }}
                                 >
                                    {isEstablished ? t('Close') : t('Cancel')}
                                 </Button>
                                 {!isEstablished ? (
                                    <Button appearance="primary" type="submit">
                                       {getActionModalBtnText(selectedItem)}
                                    </Button>
                                 ) : null}
                              </ModalActions>
                           </GridItem>
                        </Grid>
                     </form>
                  </DialogContent>
               </DialogBody>
            </DialogSurface>
         ) : null}
      </Dialog>
   )
}
