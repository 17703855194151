import { Button, Dialog, DialogContent, DialogTitle } from '@fluentui/react-components'
import React, { useContext } from 'react'
import { ModalActions, DialogSurface, DialogBody } from '../../common/modal/Modal'
import { useTranslation } from 'react-i18next'
import { DataContext } from '../../../context/DataContext'
import { LockClosed24Regular } from '@fluentui/react-icons'
import { completeCurrentAnalysis } from '../../../helpers/demandAnalysisHelper'
import { DemandAnalysis, DemandAnalysisQuestion } from '../../../api/schemas/schema'
import { Text } from '../../common/text/Text'
import { useSearchParams } from 'react-router-dom'

type ModalCompleteCurrentAnalysisProps = {
   open: boolean
   close: () => void
   items: DemandAnalysisQuestion[]
   setItems: React.Dispatch<React.SetStateAction<DemandAnalysisQuestion[]>>
   setAnalysisState: React.Dispatch<
      React.SetStateAction<{
         loading: boolean
         error: Error
         item: DemandAnalysis
      }>
   >
}
export const ModalCompleteCurrentAnalysis = ({ open, close, items, setItems, setAnalysisState }: ModalCompleteCurrentAnalysisProps) => {
   const { t } = useTranslation()
   const dataContext = useContext(DataContext)
   const [searchParams] = useSearchParams()
   const selectedProcess = searchParams.get('process')

   return (
      <Dialog open={open}>
         <DialogSurface>
            <DialogBody>
               <DialogTitle as="h3">{t('CompleteCurrentAnalysis')}</DialogTitle>
               <DialogContent>
                  <Text>{t('CompleteCurrentAnalysisConfirmationText', { process: selectedProcess })}</Text>
                  <ModalActions>
                     <Button onClick={close}>{t('Cancel')}</Button>
                     <Button
                        appearance="primary"
                        icon={<LockClosed24Regular />}
                        onClick={async () => {
                           await completeCurrentAnalysis(dataContext, items, t, setItems, setAnalysisState, close)
                        }}
                     >
                        {t('Complete')}
                     </Button>
                  </ModalActions>
               </DialogContent>
            </DialogBody>
         </DialogSurface>
      </Dialog>
   )
}
