import { ToastIntent } from '@fluentui/react-components'
import { IRootState } from '../interfaces/IRootState'

export const UpdateItemInCollection = (
   setRootState: React.Dispatch<React.SetStateAction<IRootState>>,
   collection: any[], // eslint-disable-line @typescript-eslint/no-explicit-any
   item: any, // eslint-disable-line @typescript-eslint/no-explicit-any
   stateKey: string
): void => {
   const copy = collection
   const index = copy.findIndex((p) => p.id === item.id)

   copy[index] = item
   setRootState((prev) => ({
      ...prev,
      [stateKey]: copy,
   }))
}
export const AddItemToCollection = (
   setRootState: React.Dispatch<React.SetStateAction<IRootState>>,
   collection: any[], // eslint-disable-line @typescript-eslint/no-explicit-any
   item: any, // eslint-disable-line @typescript-eslint/no-explicit-any
   stateKey: string
): void => {
   setRootState((prev) => ({
      ...prev,
      [stateKey]: [...collection, item],
   }))
}
export const AddItemsToCollection = (
   setRootState: React.Dispatch<React.SetStateAction<IRootState>>,
   collection: any[], // eslint-disable-line @typescript-eslint/no-explicit-any
   items: any[], // eslint-disable-line @typescript-eslint/no-explicit-any
   stateKey: string
): void => {
   setRootState((prev) => ({
      ...prev,
      [stateKey]: [...collection, ...items],
   }))
}
export const RemoveItemFromCollection = (
   setRootState: React.Dispatch<React.SetStateAction<IRootState>>,
   collection: any[], // eslint-disable-line @typescript-eslint/no-explicit-any
   item: any, // eslint-disable-line @typescript-eslint/no-explicit-any
   stateKey: string
): void => {
   setRootState((prev) => ({
      ...prev,
      [stateKey]: collection.filter((l) => l.id !== item.id),
   }))
}
export const setLoading = (setRootState: React.Dispatch<React.SetStateAction<IRootState>>, value: boolean) => {
   setRootState((prev) => ({ ...prev, isLoading: value }))
}

export const handleMessage = (setRootState: React.Dispatch<React.SetStateAction<IRootState>>, intent: ToastIntent, title: string, text?: string) => {
   setRootState((prev) => ({
      ...prev,
      message: {
         intent,
         title,
         text,
      },
   }))

   setTimeout(() => {
      setRootState((prev) => ({
         ...prev,
         message: null,
      }))
   }, 1000)
}
