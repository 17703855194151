import React from 'react'
import {
   DataGrid,
   DataGridHeader,
   DataGridRow,
   DataGridHeaderCell,
   DataGridBody,
   DataGridCell,
   shorthands,
   tokens,
   makeStyles,
   TableColumnDefinition,
   mergeClasses,
   TableColumnSizingOptions,
   Divider,
} from '@fluentui/react-components'
import { Title } from '../text/Title'

const useStyles = makeStyles({
   content: {
      ...shorthands.padding(tokens.spacingVerticalL, 0, tokens.spacingHorizontalXXL, 0),
   },
   header: {
      ...shorthands.margin(tokens.spacingVerticalS, 0),
   },
   divider: {
      marginBottom: tokens.spacingVerticalXXXL,
   },
   pointer: {
      cursor: 'pointer',
   },
   semibold: { '& > *': { fontWeight: tokens.fontWeightSemibold } },
})

type ListProps<T extends {}> = {
   columns: TableColumnDefinition<T>[]
   items: T[]
   onRowClick?: (item: T) => void
   title?: string
   info?: string
   resizableColumns?: boolean
   className?: string
   pagination?: boolean
   numberOfPages?: number
   columnSizingOptions?: TableColumnSizingOptions
   titleAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
   sortable?: boolean
}
export const List = <T extends {}>({ columns, items, onRowClick, title, className, resizableColumns, columnSizingOptions, sortable }: ListProps<T>) => {
   const classes = useStyles()
   return (
      <section className={mergeClasses(classes.content, className ?? '')}>
         {title && (
            <>
               <header className={classes.header}>
                  <Title as="h3">{title}</Title>
               </header>
               <Divider className={classes.divider} appearance="brand" />
            </>
         )}

         <DataGrid
            sortable={sortable ?? false}
            items={items}
            columns={columns}
            resizableColumns={resizableColumns ?? false}
            columnSizingOptions={columnSizingOptions}
         >
            <DataGridHeader>
               <DataGridRow className={classes.semibold}>{({ renderHeaderCell }) => <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>}</DataGridRow>
            </DataGridHeader>
            <DataGridBody<T>>
               {({ item, rowId }) => (
                  <DataGridRow<T> key={rowId} className={onRowClick ? classes.pointer : ''} onClick={() => onRowClick && onRowClick(item)}>
                     {({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}
                  </DataGridRow>
               )}
            </DataGridBody>
         </DataGrid>
      </section>
   )
}
