import React, { useEffect, useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { OptionGroup, Option, makeStyles, tokens, Dropdown } from '@fluentui/react-components'
import { Organisation, SNI, SNIOrganisationConnection } from '../../../api/schemas/schema'
import { useTranslation } from 'react-i18next'
import { Field } from './Field'
const useStyles = makeStyles({
   optionGroupHeader: { fontWeight: tokens.fontWeightSemibold },
   // , '&:hover': { backgroundColor: tokens.colorNeutralBackground1Hover } },
})
type SniDropdownProps = {
   organisationForm: UseFormReturn<Organisation, any, undefined>
   label: string
   items: SNI[]
   disabled?: boolean
   required?: boolean
}

export const SniDropdown = ({ organisationForm, label, items, disabled, required }: SniDropdownProps): JSX.Element => {
   const { control, setValue, watch } = organisationForm
   const id = watch('id')
   const { t } = useTranslation()
   const groups = items.map((x) => x.department).sort()
   const uniqueGroups = groups.filter((item, index, array) => array.indexOf(item) === index)
   const getDefaultSelectedGroups = (selectedSnis: SNIOrganisationConnection[]) => {
      const defaultSelectedGroups: string[] = []
      uniqueGroups.forEach((g) => {
         if (selectedSnis?.filter((x) => x.sni.department === g).length === items.filter((item) => item.department === g).length) {
            defaultSelectedGroups.push(g)
         }
      })
      if (defaultSelectedGroups.length === uniqueGroups.length) defaultSelectedGroups.push(t('All'))
      return defaultSelectedGroups
   }
   const [selectedGroups, setSelectedGroups] = useState<string[]>(getDefaultSelectedGroups(watch('snIs')))
   const classes = useStyles()

   const groupClick = (department: string) => {
      const departmentLetter = department.charAt(0)
      const currentSelectedSnIs = watch('snIs') ?? []
      const departmentItems = items.filter((x) => x.department.charAt(0) === departmentLetter)

      let newSelectedSnIs = new Set(currentSelectedSnIs.map((sn) => sn.sniId))
      departmentItems.forEach((option) => {
         newSelectedSnIs.add(option.id)
      })
      if (currentSelectedSnIs.filter((x) => x.sni && x.sni.department.charAt(0) === departmentLetter).length === departmentItems.length) {
         setValue(
            'snIs',
            currentSelectedSnIs.filter((x) => x.sni && x.sni.department.charAt(0) !== departmentLetter),
            { shouldDirty: true }
         )
      } else {
         setValue(
            'snIs',
            Array.from(newSelectedSnIs)
               .map((sniId) => ({
                  sniId: sniId,
                  organisationId: id,
                  sni: items.find((item) => item.id === sniId),
               }))
               .filter((x) => x.sni),
            { shouldDirty: true }
         )
      }
   }
   const allClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation()
      const currentSelectedSnIs = watch('snIs')
      const selectedSniIds = new Set(currentSelectedSnIs.filter((x) => x.sni).map((sn) => sn.sniId))

      if (selectedSniIds.size === items.length) {
         setValue('snIs', [], { shouldDirty: true })
      } else {
         const newSnis = items
            .filter((item) => !selectedSniIds.has(item.id))
            .map((item) => ({
               sniId: item.id,
               organisationId: id,
               sni: item,
            }))

         setValue('snIs', [...currentSelectedSnIs.filter((x) => x.sni), ...newSnis], { shouldDirty: true })
      }
   }
   useEffect(() => {
      setSelectedGroups(getDefaultSelectedGroups(watch('snIs')))
   }, [id, uniqueGroups.length, watch('snIs')])
   return (
      <Controller
         name={'snIs'}
         control={control}
         rules={{ required: required ? t('RequiredField') : false }}
         render={({ field: { value }, fieldState: { error } }) => {
            return (
               <Field label={label} required={required} validationMessage={error?.message} validationState={error ? 'error' : null}>
                  <Dropdown
                     multiselect
                     onOptionSelect={(e, data) => {
                        const selectedSnis = data.selectedOptions
                           .filter((option) => items.find((x) => x.id === option) !== undefined)
                           .map((option) => ({
                              sniId: option,
                              organisationId: id,
                              sni: items.find((x) => x.id === option),
                           }))
                        setValue('snIs', selectedSnis, { shouldDirty: true })
                     }}
                     selectedOptions={value ? [...value.sort((a, b) => a.sni.code - b.sni.code).map((x) => x?.sniId), ...selectedGroups] : []}
                     style={{ minWidth: 'auto' }}
                     value={
                        value && value.length === items.length ? t('All') : value && value.length < items.length ? value.map((x) => x.sni?.code).join(', ') : ''
                     }
                     disabled={disabled}
                  >
                     <OptionGroup>
                        <Option className={classes.optionGroupHeader} onClick={(e) => allClick(e)}>
                           {t('All')}
                        </Option>
                     </OptionGroup>
                     {uniqueGroups.map((department) => (
                        <OptionGroup key={department}>
                           <Option className={classes.optionGroupHeader} onClick={() => groupClick(department)}>
                              {department}
                           </Option>
                           {items
                              .filter((item) => item.department.charAt(0) === department.charAt(0))
                              .sort((a, b) => a.code - b.code)
                              .map((option) => (
                                 <Option onClick={(e) => e.stopPropagation()} key={option.id} value={option.id}>
                                    {option.code.toString().padStart(2, '0') + ' ' + option.comment}
                                 </Option>
                              ))}
                        </OptionGroup>
                     ))}
                  </Dropdown>
               </Field>
            )
         }}
      />
   )
}
