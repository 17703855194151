import React from 'react'
import { Divider, makeStyles, tokens } from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'
import { ActionPlan, ActionPlanStatus, DemandAnalysisQuestion } from '../../../../api/schemas/schema'
import { GridItem } from '../../../common/grid/GridItem'
import { BadgeComplianceLevel } from '../../BadgeComplianceLevel'
import { Text } from '../../../common/text/Text'
import { Avatar } from '../../../common/avatar/Avatar'
import { RTFOutput } from '../../../common/outputs/RTFOutput'

type ParentQuestionInformationProps = {
   parent: DemandAnalysisQuestion
   actionPlan: ActionPlan
}
const useStyles = makeStyles({
   marginTopXXS: {
      marginTop: tokens.spacingVerticalXXS,
   },
   marginTopXS: {
      marginTop: tokens.spacingVerticalXS,
   },
})
export const ParentQuestionInformation = ({ parent, actionPlan }: ParentQuestionInformationProps) => {
   const { t } = useTranslation()
   const classes = useStyles()
   return (
      <>
         <GridItem size="12/12">
            <Divider appearance="brand" />
         </GridItem>
         <GridItem size="4/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('Process')}
            </Text>
            <Text className={classes.marginTopXS} style={{ margin: 0 }}>
               {parent.processes.join(', ')}
            </Text>
         </GridItem>
         <GridItem size="4/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('AnsweredBy')}
            </Text>
            <Avatar className={classes.marginTopXXS} name={parent.answeredBy.title}></Avatar>
         </GridItem>
         <GridItem size="4/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('ComplianceLevel')}
            </Text>
            <BadgeComplianceLevel className={classes.marginTopXXS} text={parent.answer.text} bgHexColor={parent.answer.colorHex} />
         </GridItem>
         <GridItem size="12/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('Question')} {parent.number}
            </Text>
            <RTFOutput text={parent.text} />
         </GridItem>
         {actionPlan.status !== ActionPlanStatus.Completed && actionPlan.status !== ActionPlanStatus.Established ? (
            <GridItem size="12/12">
               <Text style={{ margin: 0 }} weight="semibold">
                  {t('ProposedAction')}
               </Text>
               <Text>{parent.proposedAction}</Text>
            </GridItem>
         ) : null}
      </>
   )
}
