type DropdownOption = {
   key: string
   text: string
   disabled?: boolean
}

export const convertToDropdownOption = (items: any[]): DropdownOption[] => {
   return items.map((item) => ({
      key: item.id,
      text: item.text,
   }))
}
