import React, { useContext } from 'react'
import { makeStyles, tokens, mergeClasses, TabList, SelectTabData } from '@fluentui/react-components'
import { useNavigate } from 'react-router-dom'
import { Text } from '../text/Text'
import { DataContext } from '../../../context/DataContext'
type NavigationProps = {
   customOnTabSelect?: (e: any, data: SelectTabData) => void
   desktopNavLinks: JSX.Element[] | JSX.Element
   phoneNavLinks?: JSX.Element[] | JSX.Element
   activeDesktopTab: string
   activePhoneTab?: string
}
const useStyles = makeStyles({
   nav: {
      position: 'fixed',
      boxShadow: tokens.shadow8,
      zIndex: 3,
      backgroundColor: tokens.colorNeutralBackground1,
      paddingLeft: '2px',
      paddingRight: '2px',
      left: 0,
   },
   desktopNav: {
      display: 'flex',
      justifyContent: 'space-between',
      height: 'calc(100vh - 60px)',
      top: '60px',
      width: '60px',
      flexDirection: 'column',
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },
   phoneNav: {
      display: 'none',
      '@media screen and (max-width: 1007px)': {
         display: 'flex',
         bottom: 0,
         width: '100%',
         height: '50px',
         justifyContent: 'center',
         top: 'auto',
      },
   },
   expanded: {
      width: '316px',
      height: '100%',
      justifyContent: 'start',
      alignItems: 'start',
      backgroundColor: tokens.colorNeutralBackground1,
   },

   tabList: {
      width: '100%',

      '& button': {
         paddingLeft: '18px',
      },
      '@media screen and (max-width: 1007px)': {
         justifyContent: 'space-around',
         '& button': {
            paddingLeft: '10px',
         },
      },
   },
})

export const Navigation = ({ customOnTabSelect, desktopNavLinks, phoneNavLinks, activeDesktopTab, activePhoneTab }: NavigationProps) => {
   const dataContext = useContext(DataContext)
   const packageJson = require('../../../../package.json')
   const { navigationExpanded } = dataContext.state
   const classes = useStyles()
   const navigate = useNavigate()
   const onTabSelect = customOnTabSelect
      ? customOnTabSelect
      : (e: any, data: SelectTabData) => {
           navigate(data.value)
        }
   return (
      <>
         <aside className={mergeClasses(classes.nav, classes.desktopNav, navigationExpanded ? classes.expanded : '')}>
            <TabList vertical selectedValue={activeDesktopTab} size="large" onTabSelect={onTabSelect} className={classes.tabList}>
               {desktopNavLinks}
            </TabList>
            <Text size={300}>v. {packageJson.version}</Text>
         </aside>
         <footer className={mergeClasses(classes.nav, classes.phoneNav, navigationExpanded ? classes.expanded : '')}>
            <TabList selectedValue={activePhoneTab ? activePhoneTab : activeDesktopTab} size="large" onTabSelect={onTabSelect} className={classes.tabList}>
               {phoneNavLinks ? phoneNavLinks : desktopNavLinks}
            </TabList>
         </footer>
      </>
   )
}
