import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card } from '../../common/card/Card'
import { List } from '../../common/list/List'
import { Button, Divider, MessageBar, MessageBarBody, MessageBarTitle, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { AddCircle24Regular } from '@fluentui/react-icons'
import { getFRARiskIdentifyingColumns } from '../../../helpers/riskAnalysisHelper'
import { RiskAnalysis } from '../../../api/schemas/schema'
import { IRiskAnalysisState } from '../../../interfaces/IRiskAnalysisState'
import { ModalAddRisk } from './ModalAddRisk'
import { Text } from '../../common/text/Text'
const useStyles = makeStyles({
   hideInPhone: {
      '@media screen and (max-width: 640px)': {
         display: 'none',
      },
   },
   showOnlyPhone: {
      display: 'none',
      '@media screen and (max-width: 640px)': {
         display: 'block',
      },
   },
   text: {
      ...shorthands.margin(tokens.spacingHorizontalS, 0),
   },
   spaceBtw: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
   },
})
type IdentifyingProps = {
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IRiskAnalysisState>>
   analysis: RiskAnalysis
}
export const Identifying = ({ setRiskAnalysisState, analysis }: IdentifyingProps) => {
   const classes = useStyles()
   const { t } = useTranslation()
   const [open, setOpen] = useState<boolean>(false)

   return (
      <Card
         title={t('RiskIdentifying')}
         action={
            <Button onClick={() => setOpen(true)} appearance="primary" icon={<AddCircle24Regular />}>
               {t('NewRisk')}
            </Button>
         }
         transparentInPhone
      >
         <List columns={getFRARiskIdentifyingColumns()} items={analysis.risks} className={classes.hideInPhone} />
         {analysis.risks.length === 0 && (
            <MessageBar intent={'warning'}>
               <MessageBarBody>
                  <MessageBarTitle>{t('NoRisksIdentifiedYet')}</MessageBarTitle>
               </MessageBarBody>
            </MessageBar>
         )}
         <div className={classes.showOnlyPhone}>
            <div>
               {analysis.risks.length > 0
                  ? analysis.risks.map((x) => (
                       <>
                          <div>
                             <Text className={classes.text}>{x.scenario}</Text>
                             <Text className={classes.text} weight={'bold'}>
                                {x.protectedAsset.name}
                             </Text>
                          </div>
                          <Divider />
                       </>
                    ))
                  : null}
            </div>
            <Button style={{ width: '100%', display: 'inline-flex' }} onClick={() => setOpen(true)} appearance="primary" icon={<AddCircle24Regular />}>
               {t('NewRisk')}
            </Button>
         </div>
         <ModalAddRisk setRiskAnalysisState={setRiskAnalysisState} analysis={analysis} open={open} setOpen={setOpen} />
      </Card>
   )
}
