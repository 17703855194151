import React from 'react'
import { useMsal } from '@azure/msal-react'
import { Button, LargeTitle, makeStyles } from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'
import { Text } from '../components/common/text/Text'

const useStyles = makeStyles({
   wrapper: {
      position: 'absolute',
      top: '30%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
   },
   appVersion: {
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translate(-50%, -50%)',
   },
})

export const Login = () => {
   const classes = useStyles()
   const { instance } = useMsal()
   const { t } = useTranslation()
   const packageJson = require('../../package.json')
   const appVersion = packageJson.version

   const initializeSignIn = () => {
      instance.loginRedirect()
   }
   return (
      <article>
         <div className={classes.wrapper}>
            <LargeTitle align="center" as="h2">{`${t('WelcomeTo')} ${t('365Robust')}`}</LargeTitle>
            <div>
               <Button size="large" appearance="primary" onClick={initializeSignIn}>
                  {t('SignIn')}
               </Button>
            </div>
         </div>
         <Text className={classes.appVersion} align="center">
            v {appVersion}
         </Text>
      </article>
   )
}
