import { IDataContext } from '../interfaces/IDataContext'
import { IRootState } from '../interfaces/IRootState'

export const initialDataContext: IDataContext = {
   state: null,
   setRootState: null,
   handleMessage: null,
}
export const GetInitialRootState = (): IRootState => ({
   navigationExpanded: false,
   isLoading: false,
   message: null,
   initialLoading: true,
   userAccount: null,
   snis: [],
   organisation: null,
   branch: null,
   protectedAssets: [],
   accessToken: null,
   userAccounts: [],
})
