import React from 'react'
import { App } from './App'
import { PublicClientApplication } from '@azure/msal-browser'
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react'
import { msalConfig } from './auth/authConfig'
import { Login } from './pages/Login'
import { DataContextProvider } from './context/DataContext'
export const msalInstance = new PublicClientApplication(msalConfig)

export const Root = () => {
   return (
      <MsalProvider instance={msalInstance}>
         <AuthenticatedTemplate>
            <DataContextProvider>
               <App />
            </DataContextProvider>
         </AuthenticatedTemplate>
         <UnauthenticatedTemplate>
            <Login />
         </UnauthenticatedTemplate>
      </MsalProvider>
   )
}
