import React from 'react'
import { Badge as FluentBadge } from '@fluentui/react-components'

type BadgeProps = {
   appearance?: 'tint' | 'filled' | 'outline'
   children?: string | number | JSX.Element
   color?: 'brand' | 'danger' | 'important' | 'informative' | 'severe' | 'subtle' | 'success' | 'warning'
   style?: React.CSSProperties
   icon?: JSX.Element
   role?: React.AriaRole
   className?: string
   shape?: 'circular' | 'rounded' | 'square'
   onClick?: () => void
}
export const Badge = ({ color, appearance, style, children, icon, role, className, shape, onClick }: BadgeProps) => {
   return (
      <FluentBadge
         className={className ?? ''}
         icon={icon}
         color={color}
         appearance={appearance ?? 'tint'}
         style={style}
         size="large"
         role={role ?? ''}
         shape={shape ?? 'circular'}
         onClick={onClick}
      >
         {children}
      </FluentBadge>
   )
}
