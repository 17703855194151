import React from 'react'
import { Layout } from './Layout'
import { useTranslation } from 'react-i18next'
import { GridItem } from '../components/common/grid/GridItem'
import { Grid } from '../components/common/grid/Grid'
import { MessageBar, MessageBarBody, MessageBarTitle, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { Navigation } from '../components/common/navigation/Navigation'
import { BaseNavLinks } from '../components/common/navigation/BaseNavLinks'

const useStyles = makeStyles({
   staticSkeleton: {
      backgroundImage: `linear-gradient(90deg, ${tokens.colorNeutralBackground5} 0%, ${tokens.colorNeutralBackground2} 50%, ${tokens.colorNeutralBackground5} 85%)`,
      height: '250px',
      ...shorthands.border('solid', '1px', tokens.colorNeutralStroke2),
      ...shorthands.borderRadius(tokens.borderRadiusLarge),
   },
})
export const Dashboard = () => {
   const { t } = useTranslation()
   const classes = useStyles()
   return (
      <Layout breadCrumbItems={[{ title: t('Dashboard'), active: true, path: '/' }]}>
         <Navigation desktopNavLinks={<BaseNavLinks active="/" />} activeDesktopTab="/" />
         <MessageBar>
            <MessageBarBody>
               <MessageBarTitle>Denna sida är ännu inte utvecklad.</MessageBarTitle>
            </MessageBarBody>
         </MessageBar>
         <Grid>
            <GridItem size="3/12" className={classes.staticSkeleton} />
            <GridItem size="2/12" className={classes.staticSkeleton} />
            <GridItem size="2/12" className={classes.staticSkeleton} />
            <GridItem size="2/12" className={classes.staticSkeleton} />
            <GridItem size="3/12" className={classes.staticSkeleton} />
            <GridItem size="12/12" className={classes.staticSkeleton} />
            <GridItem size="12/12" className={classes.staticSkeleton} />
            <GridItem size="12/12" className={classes.staticSkeleton} />
         </Grid>
      </Layout>
   )
}
