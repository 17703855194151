import React from 'react'
import {
   DataGrid,
   DataGridBody,
   DataGridCell,
   DataGridHeader,
   DataGridHeaderCell,
   DataGridRow,
   makeStyles,
   mergeClasses,
   TableColumnDefinition,
   tokens,
} from '@fluentui/react-components'

const useStyles = makeStyles({
   row: {
      cursor: 'pointer',
      '& > div': {
         flex: '0 1 auto',
      },
   },
   semibold: { '& > *': { fontWeight: tokens.fontWeightSemibold } },
})
type ListProps<T extends {}> = {
   columns: TableColumnDefinition<T>[]
   items: T[]
   onRowClick?: (item: T) => void
   title?: string
   rowClassName: string
   className?: string
}
export const CustomList = <T extends {}>({ columns, items, onRowClick, rowClassName, className }: ListProps<T>) => {
   const classes = useStyles()
   return (
      <DataGrid items={items} columns={columns} sortable className={className ?? ''}>
         <DataGridHeader>
            <DataGridRow className={mergeClasses(classes.semibold, classes.row, rowClassName)}>
               {({ renderHeaderCell }) => <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>}
            </DataGridRow>
         </DataGridHeader>
         <DataGridBody<T>>
            {({ item, rowId }) => (
               <DataGridRow<T> onClick={() => onRowClick && onRowClick(item)} className={mergeClasses(classes.row, rowClassName)} key={rowId}>
                  {({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}
               </DataGridRow>
            )}
         </DataGridBody>
      </DataGrid>
   )
}
