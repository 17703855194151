import React, { useEffect, useState } from 'react'
import { DemandAnalysis, DemandAnalysisQuestionStatus } from '../../../api/schemas/schema'
import { List } from '../../common/list/List'
import { t } from 'i18next'
import { DemandAnalysisTabs, getDemandAnalysisStatusColumns } from '../../../helpers/demandAnalysisHelper'
import { IAnalysisStatusData } from '../../../interfaces/IAnalysisStatusData'
import { makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components'
import { Text } from '../../common/text/Text'
import { LockClosed20Regular, Subtract20Regular, ArrowClockwise20Regular } from '@fluentui/react-icons'
import { Avatar } from '../../common/avatar/Avatar'
import { useSearchParams } from 'react-router-dom'
import { Badge } from '../../common/badge/Badge'

type StatusProps = {
   analysis: DemandAnalysis
}
const useStyles = makeStyles({
   noneInPhone: {
      '@media screen and (max-width: 1007px)': { display: 'none' },
   },
   onlyInPhone: {
      display: 'none',
      '@media screen and (max-width: 1007px)': { display: 'block' },
   },
   spaceBtw: {
      display: 'flex',
      justifyContent: 'space-between',
   },
   column: {
      display: 'flex',
      flexDirection: 'column',

      ...shorthands.gap(tokens.spacingVerticalS),
   },
   mobileContentWrapper: {
      display: 'none',
      '@media screen and (max-width: 1007px)': {
         display: 'flex',
         flexDirection: 'column',
         ...shorthands.gap(tokens.spacingVerticalXXL),
      },
   },
   contentEnd: {
      alignItems: 'end',
   },
})
export const Status = ({ analysis }: StatusProps) => {
   const [analysisStatusData, setAnalysisStatusData] = useState<IAnalysisStatusData[]>([])
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [searchParams, setSearchParams] = useSearchParams()
   const classes = useStyles()
   useEffect(() => {
      const data: IAnalysisStatusData[] = []
      const allProcesses: string[] = []
      analysis.questions.forEach((x) => {
         if (!allProcesses.find((z) => z === x.processes[0])) {
            allProcesses.push(x.processes[0])
         }
      })

      allProcesses.forEach((x) => {
         const relevantEntries = analysis.questions.filter((question) => question.processes.includes(x)) ?? []
         if (relevantEntries.length > 0) {
            let numberOfAnsweredQuestions = 0
            relevantEntries.forEach((question) => {
               if (question.status !== DemandAnalysisQuestionStatus.NotAnswered) numberOfAnsweredQuestions += 1
            })
            const status: string = relevantEntries.every((x) => x.status === DemandAnalysisQuestionStatus.Established)
               ? t('Completed')
               : relevantEntries.some((x) => x.status === DemandAnalysisQuestionStatus.Answered)
               ? t('Started')
               : t('NotStarted')
            data.push({
               process: x,
               modified: relevantEntries.sort((a, b) => {
                  if (!a.modified && !b.modified) {
                     return 0
                  }
                  if (!a.modified) {
                     return 1
                  }
                  if (!b.modified) {
                     return -1
                  }
                  return new Date(b.modified).getTime() - new Date(a.modified).getTime()
               })[0].modified,
               numberOfAnsweredQuestions: numberOfAnsweredQuestions,
               numberOfTotalQuestions: relevantEntries.length,
               processOwner: { title: 'Mockad testarsson' },
               status,
            })
         }
         setAnalysisStatusData(data.sort((a, b) => a.process.localeCompare(b.process)))
      })
   }, [])
   const openProcess = (item: IAnalysisStatusData) => {
      setSearchParams((prev) => ({ ...prev, tab: DemandAnalysisTabs.CurrentAnalysis, process: item.process }))
   }
   return (
      <>
         <article className={mergeClasses(classes.mobileContentWrapper)}>
            {analysisStatusData.map((x) => (
               <div className={classes.spaceBtw} onClick={() => openProcess(x)}>
                  <span className={classes.column}>
                     <Text style={{ margin: '0' }} weight="bold">
                        {x.process}
                     </Text>
                     <Avatar name={x.processOwner.title} />
                  </span>
                  <span className={mergeClasses(classes.column, classes.contentEnd)}>
                     <Badge
                        appearance="tint"
                        style={{ minWidth: '40px' }}
                        icon={
                           x.status === t('Completed') ? (
                              <LockClosed20Regular />
                           ) : x.status === t('Started') ? (
                              <ArrowClockwise20Regular />
                           ) : (
                              <Subtract20Regular />
                           )
                        }
                        color={x.status === t('Completed') ? 'informative' : x.status === t('Started') ? 'warning' : 'danger'}
                     />
                     <Text style={{ margin: '0' }}>{`${x.numberOfAnsweredQuestions}/${x.numberOfTotalQuestions}`}</Text>
                  </span>
               </div>
            ))}
         </article>

         <List
            onRowClick={(item) => openProcess(item)}
            title={t('AnalysisStatus')}
            items={analysisStatusData}
            columns={getDemandAnalysisStatusColumns(t)}
            className={classes.noneInPhone}
         />
      </>
   )
}
