import React from 'react'
import { Divider, makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components'
import { Title } from '../text/Title'

const useStyles = makeStyles({
   card: {
      ...shorthands.borderRadius(tokens.borderRadiusLarge),
      display: 'flex',
      flexDirection: 'column',
      ...shorthands.gap(tokens.spacingHorizontalM),
   },
   beige: {
      boxShadow: tokens.shadow8,
      backgroundColor: '#FFFDF5',
      ...shorthands.padding(tokens.spacingVerticalXXL, tokens.spacingHorizontalXXXL),
   },
   transparentInPhone: {
      ...shorthands.padding(tokens.spacingVerticalXXL, tokens.spacingHorizontalXXXL),
      boxShadow: tokens.shadow8,
      backgroundColor: '#FFFDF5',
      '@media screen and (max-width: 1007px)': {
         boxShadow: 'none',
         ...shorthands.padding(0),
         backgroundColor: 'transparent',
      },
   },
   showOnlyLarge: {
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },
   header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
   },
})
type CardProps = {
   title: string
   titleAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
   children: JSX.Element | JSX.Element[] | string | string[]
   className?: string
   contentClassName?: string
   transparentInPhone?: boolean
   action?: JSX.Element
}
export const Card = ({ title, titleAs, children, className, transparentInPhone, contentClassName, action }: CardProps) => {
   const classes = useStyles()
   return (
      <article aria-label="group" className={mergeClasses(transparentInPhone ? classes.transparentInPhone : classes.beige, classes.card, className ?? '')}>
         <header className={mergeClasses(classes.showOnlyLarge, classes.header)}>
            <Title as={titleAs ?? 'h2'}>{title}</Title>
            {action ? action : null}
         </header>
         <Divider className={classes.showOnlyLarge} appearance="brand" />
         <main className={contentClassName ?? ''}>{children}</main>
      </article>
   )
}
