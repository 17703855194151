export const sv = {
   '365Robust': '365Robust',

   WelcomeTo: 'Välkommen till',
   SignIn: 'Logga in',
   SignOut: 'Logga ut',
   Dashboard: 'Dashboard',

   // Organisation
   OrganisationalData: 'Organisationsuppgifter',
   OrganisationNumber: 'Organisationsnummer',
   CompanyName: 'Företagsnamn',
   OrganisationSize: 'Organisationsstorlek',
   OrganisationType: 'Organisationsform',
   SNI: 'SNI',
   All: 'Alla',

   // Button
   Next: 'Nästa',
   Add: 'Lägg till',
   Finish: 'Avsluta',
   Cancel: 'Avbryt',
   Continue: 'Fortsätt',
   Save: 'Spara',
   ReadMore: 'Läs mer',
   Close: 'Stäng',
   Complete: 'Fastställ',
   Establish: 'Fastställ',

   // Roles
   ProcessOwner: 'Processägare',
   AnalysisOwner: 'Analysägare',
   RiskOwner: 'Riskägare',
   AnalysisLeader: 'Analysledare',

   // Billing
   BillingData: 'Fakturauppgifter',
   InvoiceReference: 'Fakturareferens',
   InvoiceAddress: 'Fakturaadress',
   PostalCode: 'Postnummer',
   County: 'Postort',
   PhoneNumber: 'Telefonnummer',
   Email: 'E-post',

   // Branch
   Branch: 'Verksamhet',
   BranchName: 'Namn på verksamhet',
   PropertyDesignation: 'Fastighetsbeteckning',
   OrganisationForm: 'Organisationsform',
   SNICodes: 'SNI-koder',
   BranchDescription: 'Verksamhetsbeskrivning',
   BranchValuation: 'Värdering verksamhet',
   DateForValuation: 'Datum för värdering',
   BranchResponsible: 'Verksamhetsansvarig',
   VisitingAdress: 'Besöksaddress',
   KeyFigures: 'Nyckeltal',

   // ProtectedAssets
   ProtectedAssets: 'Skyddsvärda tillgångar',
   ProtectedAsset: 'Skyddsvärd tillgång',
   BranchAffiliation: 'Verksamhetstillhörighet',
   DescriptionOfAsset: 'Beskrivning av tillgång',
   Description: 'Beskrivning',
   ProtectedAssetValuation: 'Värdering av tillgång',
   AddedAssets: 'Tillagda tillgångar',
   Ongoing: 'Pågående',
   Concluded: 'Avslutade',
   NewDemandAnalysis: 'Ny kravanalys',
   OngoingAnalysis: 'Pågående analys',
   OngoingDemandAnalyses: 'Pågående kravanalyser',
   ConcludedDemandAnalyses: 'Avslutade kravanalyser',
   AnalysisName: 'Analysnamn',
   AccessAffectsFollowingProcesses: 'Tillgång påverkar följande processer',
   MotivationAndStand: 'Motivering och ställningstagande',

   // ComplianceOfDemands
   ComplianceOfDemands: 'Kravefterlevnad',
   LeadershipPerspective: 'Ledningsperspektiv',
   Subject: 'Ämne',
   DataClassification: 'Informationsklassning',
   AnalysisStart: 'Start av analys',
   AnalysisEnd: 'Avslut av analys',
   LastDateOfAnalyis: 'Datum för avslut',
   FirstDateOfAnalysis: 'Datum för analysstart',
   Comment: 'Kommentar',
   CreateAnalysis: 'Skapa analys',
   Process: 'Process',
   CreatingDemandAnalysis: 'Skapar upp kravanalys...',
   DemandAnalysisCreated: 'Din kravanalys är nu skapad',
   ThisCanTakeAMoment: 'Detta kan ta en stund. Håll enheten öppen',
   ClickContinueToGoToAnalysis: "Klicka på 'Fortsätt' för att gå till din analys",
   AnalysisInformation: 'Analysinformation',
   AnalysisStatus: 'Analysstatus',
   CurrentSituationAnalysis: 'Nulägesanalys',
   ActionPlan: 'Åtgärdsplan',
   DemandAnalysis: 'Kravanalys',
   BranchScope: 'Verksamhetsomfattning',
   CompleteDemandAnalysis: 'Avsluta kravanalys',
   CompleteDemandAnalysisConfirmationMessage: 'Är du säker på att du vill avsluta kravanalysen? Det går inte att ångra sig när analysen har blivit avslutad.',
   AllAnswered: 'Besvarade',
   NoConcludedDemandAnalyses: 'Det finns inga avslutade kravanalyser',
   TimeToCreateYourFirstDemandAnalysis: 'Dags att skapa din första kravanalys',
   AllProcesses: 'Alla processer',
   'No.': 'Nr',
   Question: 'Fråga',
   CompleteCurrentAnalysis: 'Fastställ nulägesanalys',
   CompleteCurrentAnalysisConfirmationText:
      'Är du säker på att du vill fastställa nulägesanalysen för {{process}}? Svaret på frågorna kan ej ändras och åtgärdsplaner skapas upp.',
   ProposedAction: 'Förslag till åtgärd',
   BackgroundInformation: 'Bakgrundsfakta',
   TipFrom365Robust: '365Robust tipsar',
   OriginDemandForTheQuestion: 'Ursprungskrav till frågan',
   OriginDemand: 'Ursprungskrav',
   Source: 'Källa',
   DemandAnalysisCompleted: 'Kravanalys avslutad',
   CouldNotCompleteDemandAnalysis: 'Kunde ej avsluta kravanalysen',

   // Actionplans
   NoActionPlansCreated: 'Inga åtgärdsplaner skapade ännu',
   NoActionPlansRequiredForThisDemandAnalysis: 'Inga åtgärder behöver vidtas för i denna kravanalys',
   ComplianceLevel: 'Efterlevnadsnivå',
   DecisionOnAction: 'Beslut om åtgärd',
   NoActionShallBeTaken: 'Inga åtgärder ska vidtas',
   ResponsibleOfAction: 'Ansvarig för åtgärd',
   Priority: 'Prioritet',
   ID: 'ID',
   AnsweredBy: 'Besvarad av',
   StartAction: 'Påbörja åtgärd',
   CompleteAction: 'Avsluta åtgärd',
   ActionDecidedBy: 'Åtgärd beslutad av',
   NoActionsTaken: 'Inga åtgärder genomfördes',
   ImplementedActions: 'Genomförda åtgärder',
   ComplianceLevelAfterCompletedAction: 'Kravefterlevnad efter utförd åtgärd',
   SpecifyCompliance: 'Ange efterlevnad',

   // Status
   RequiredField: 'Vänligen, fyll i detta fält',
   SomethingWentWrong: 'Något gick fel',
   FetchFailed: 'Hämtning misslyckades',
   ModifiedBy: 'Senast ändrad av',
   Modified: 'Senast ändrad',
   CreatedBy: 'Skapad av',
   CreatedDate: 'Skapad',
   ItemSuccessfullySaved: '{{Item}} sparades korrekt',
   ItemSuccessfullyUpdated: '{{Item}} uppdaterades korrekt',
   CouldNotFetchItem: '{{Item}} kunde inte hämtas',
   CouldNotCreateItem: '{{Item}} kunde inte skapas',
   Status: 'Status',
   NoDateSet: 'Inget datum satt',
   NoValueSet: 'Inget värde satt',
   ValidationFailed: 'Validering misslyckades',
   CurrentAnalysisCompleted: 'Nulägesanalysen är fastställd',
   CouldNotCompleteCurrentAnalysis: 'Något gick fel när nulägesanalysen skulle fastställas',
   NotAnswered: 'Ej besvarad',
   Answered: 'Besvarad',
   Established: 'Fastställd',
   NotDecided: 'Ej beslutad',
   NotStarted: 'Ej påbörjad',
   Started: 'Påbörjad',
   Completed: 'Avslutad',
   NotToBeAdressed: 'Åtgärdas ej',
   ErrorMessage: 'Felmeddelande',
   NoDataCreatedYet: 'Ingen data skapad ännu.',

   // Riskmanagement:
   RiskManagement: 'Riskhantering',
   NewRiskAnalysis: 'Ny riskanalys',
   TypeOfRiskAnalysis: 'Typ av riskanalys',
   NameOfAnalysis: 'Namn på analys',
   'Purpose/Scope': 'Syfte/Omfattning',
   Limitation: 'Avgränsningar',
   StartOfAnalysis: 'Start av analys',
   EndOfAnalysis: 'Avslut av analys',
   SimplifiedRiskAnalysis: 'Förenklad riskanalys (FRA)',
   SimplifiedConsequenseProbabilityAnalysis: 'Förenklad konsekvens- och sannolikhetsanalys (F-KSA)',
   SWOTAnalysis: 'SWOT-analys',
   Type: 'Typ',
   NoConcludedRiskAnalyses: 'Det finns inga avslutade riskanalyser',
   TimeToCreateYourFirstRiskAnalysis: 'Dags att skapa din första riskanalys',
   OngoingRiskAnalyses: 'Pågående riskanalyser',
   ConcludedRiskAnalyses: 'Avslutade riskanalyser',
   CreatingRiskAnalysis: 'Skapar riskanalys',
   RiskAnalysisCreated: 'Din riskanalys är nu skapad',
   RiskIdentifying: 'Riskidentifiering',
   'RiskAnalysis/assessment': 'Riskanalys/värdering',
   RiskManagementPlan: 'Riskhanteringsplan',
   NewRisk: 'Ny risk',
   RiskScenario: 'Riskscenario',
   RiskSource: 'Riskkälla',
   IdentifyRisk: 'Identifiera risk',
   CurrentProtectionAndOrPotentialWeaknesses: 'Nuvarande skydd och/eller eventuella svagheter',
   UnderlyingMotivationAndStand: 'Underliggande motivering och ställningstagande',
   Consequense: 'Konsekvens',
   NoRisksIdentifiedYet: 'Inga risker har ännu identifierats',

   // Settings
   Settings: 'Inställningar',

   // Date picker strings
   January: 'Januari',
   February: 'Februari',
   March: 'Mars',
   April: 'April',
   May: 'Maj',
   June: 'Juni',
   July: 'Juli',
   August: 'Augusti',
   September: 'September',
   October: 'Oktober',
   November: 'November',
   December: 'December',

   Jan: 'Jan',
   Feb: 'Feb',
   Mar: 'Mar',
   Apr: 'Apr',
   MayShort: 'Maj',
   Jun: 'Jun',
   Jul: 'Jul',
   Aug: 'Aug',
   Sep: 'Sep',
   Oct: 'Okt',
   Nov: 'Nov',
   Dec: 'Dec',

   Monday: 'Måndag',
   Tuesday: 'Tisdag',
   Wednesday: 'Onsdag',
   Thursday: 'Torsdag',
   Friday: 'Fredag',
   Saturday: 'Lördag',
   Sunday: 'Söndag',

   MondayShort: 'M',
   TuesdayShort: 'T',
   WednesdayShort: 'O',
   ThursdayShort: 'T',
   FridayShort: 'F',
   SaturdayShort: 'L',
   SundayShort: 'S',

   GoToToday: 'Gå till idag',
}
