import { TableColumnDefinition } from '@fluentui/react-components'
import { ActionPlan, ActionPlanStatus, AnswerOptionAnalysisQuestion, DemandAnalysis, DemandAnalysisQuestion } from '../api/schemas/schema'
import { t } from 'i18next'
import { BadgeComplianceLevel } from '../components/demandAnalysis/BadgeComplianceLevel'
import { actionPlanStatusString } from './enumHelper'
import { BadgeActionPlanStatus } from '../components/demandAnalysis/BadgeActionPlanStatus'
import { ActionDecision } from '../components/demandAnalysis/actionPlan/modalContent/ActionDecision'
import { UseFormReset, UseFormReturn } from 'react-hook-form'
import { handleMessage } from './stateHelper'
import { IDataContext } from '../interfaces/IDataContext'
import { api } from './apiHelper'
import { ActionPlanInfo } from '../components/demandAnalysis/actionPlan/modalContent/ActionPlanInfo'
import { CompleteAction } from '../components/demandAnalysis/actionPlan/modalContent/CompleteAction'
import { EstalishAction } from '../components/demandAnalysis/actionPlan/modalContent/EstalishAction'

export const filterDemandActionPlansByProcess = (actionPlans: ActionPlan[], questions: DemandAnalysisQuestion[], selectedProcess: string) => {
   if (selectedProcess === t('AllProcesses')) {
      return actionPlans
   } else {
      return actionPlans.filter((x) => findQuestionParentToActionPlan(questions, x).processes.some((p) => p === selectedProcess))
   }
}

export const findQuestionParentToActionPlan = (questions: DemandAnalysisQuestion[], actionPlan: ActionPlan) =>
   questions.find((q) => q.id === actionPlan.demandAnalysisQuestionId)

export const getActionPlanColumns = (questions: DemandAnalysisQuestion[]): TableColumnDefinition<ActionPlan>[] => [
   {
      columnId: 'id',
      compare: (a, b) => findQuestionParentToActionPlan(questions, a).number - findQuestionParentToActionPlan(questions, b).number,
      renderCell: (item) => item.index,
      renderHeaderCell: () => t('ID'),
   },
   {
      columnId: 'complianceLevel',
      compare: (a, b) => findQuestionParentToActionPlan(questions, a).answer.text.localeCompare(findQuestionParentToActionPlan(questions, b).answer.text),
      renderCell: (item) => {
         const parent = findQuestionParentToActionPlan(questions, item)
         return <BadgeComplianceLevel text={parent.answer.text} bgHexColor={parent.answer.colorHex} />
      },
      renderHeaderCell: () => t('ComplianceLevel'),
   },
   {
      columnId: 'question',
      compare: (a, b) => findQuestionParentToActionPlan(questions, a).text.localeCompare(findQuestionParentToActionPlan(questions, b).text),
      renderCell: (item) => findQuestionParentToActionPlan(questions, item).text,
      renderHeaderCell: () => t('Question'),
   },
   {
      columnId: 'process',
      compare: (a, b) => findQuestionParentToActionPlan(questions, a).processes[0].localeCompare(findQuestionParentToActionPlan(questions, b).processes[0]),
      renderCell: (item) => findQuestionParentToActionPlan(questions, item).processes.join(', '),
      renderHeaderCell: () => t('Process'),
   },
   {
      columnId: 'status',
      compare: (a, b) => actionPlanStatusString(a.status, t).localeCompare(actionPlanStatusString(b.status, t)),
      renderCell: (item) => <BadgeActionPlanStatus status={item.status} />,
      renderHeaderCell: () => t('Status'),
   },
]

export const getActionModalContent = (
   selectedItem: ActionPlan,
   actionPlanForm: UseFormReturn<ActionPlan, any, undefined>,
   parent: DemandAnalysisQuestion,
   setUpdatedAnswer: React.Dispatch<React.SetStateAction<AnswerOptionAnalysisQuestion>>,
   updatedAnswer: AnswerOptionAnalysisQuestion
): JSX.Element => {
   switch (selectedItem.status) {
      case ActionPlanStatus.NotDecided:
      case ActionPlanStatus.NotToBeAdressed:
         return <ActionDecision actionPlanForm={actionPlanForm} parent={parent} />
      case ActionPlanStatus.NotStarted:
         return <ActionPlanInfo actionPlan={selectedItem} />
      case ActionPlanStatus.Started:
         return <CompleteAction actionPlanForm={actionPlanForm} actionPlan={selectedItem} />
      case ActionPlanStatus.Completed:
      case ActionPlanStatus.Established:
         return <EstalishAction actionPlan={selectedItem} parent={parent} updatedAnswer={updatedAnswer} setUpdatedAnswer={setUpdatedAnswer} />
   }
}

export const getActionModalTitle = (selectedItem: ActionPlan): string => {
   switch (selectedItem.status) {
      case ActionPlanStatus.NotDecided:
      case ActionPlanStatus.NotToBeAdressed:
         return t('DecisionOnAction')
      case ActionPlanStatus.NotStarted:
         return t('StartAction')
      case ActionPlanStatus.Started:
         return t('CompleteAction')
      case ActionPlanStatus.Completed:
         return t('ComplianceLevelAfterCompletedAction')
   }
}

export const getActionModalBtnText = (selectedItem: ActionPlan): string => {
   switch (selectedItem.status) {
      case ActionPlanStatus.NotDecided:
      case ActionPlanStatus.NotToBeAdressed:
         return t('Save')
      case ActionPlanStatus.NotStarted:
         return t('StartAction')
      case ActionPlanStatus.Started:
         return t('CompleteAction')
      case ActionPlanStatus.Completed:
         return t('Establish')
   }
}

export const submitActionPlan = async (
   data: ActionPlan,
   dataContext: IDataContext,
   close: () => void,
   setAnalysisState: React.Dispatch<
      React.SetStateAction<{
         loading: boolean
         error: Error
         item: DemandAnalysis
      }>
   >,
   reset: UseFormReset<ActionPlan>,
   parent: DemandAnalysisQuestion,
   updatedAnswer: AnswerOptionAnalysisQuestion
) => {
   try {
      const { userAccount, accessToken } = dataContext.state
      const updatedParent: DemandAnalysisQuestion = updatedAnswer
         ? {
              ...parent,
              answerUpdatedBy: userAccount,
              answerUpdatedById: userAccount.id,
              updatedAnswer: updatedAnswer,
              updatedAnswerId: updatedAnswer.id,
              modifiedBy: userAccount,
              modifiedById: userAccount.id,
           }
         : parent
      if (data.status === ActionPlanStatus.NotDecided || data.status === ActionPlanStatus.NotToBeAdressed) {
         data.decidedBy = userAccount
         data.decidedById = userAccount.id
      } else if (data.status === ActionPlanStatus.Started) {
         data.implementedBy = userAccount
         data.implementedById = userAccount.id
      } else if (data.status === ActionPlanStatus.Completed) {
         await api(accessToken).updateDemandAnalysisQuestion(updatedParent)
      }
      data.modifiedBy = userAccount
      data.modifiedById = userAccount.id

      const updatedActionPlan = (await api(accessToken).updateActionPlan({ demandAnalysisId: updatedParent.demandAnalysisId }, data)).data
      setAnalysisState((prev) => {
         const actionPlans = [...prev.item.actionPlans]
         actionPlans[actionPlans.findIndex((x) => x.id === updatedActionPlan.id)] = updatedActionPlan
         const questions = [...prev.item.questions]
         questions[questions.findIndex((x) => x.id === updatedParent.id)] = updatedParent

         return { ...prev, item: { ...prev.item, actionPlans, questions } }
      })
      handleMessage(dataContext.setRootState, 'success', t('ItemSuccessfullyUpdated', { Item: t('ActionPlan') }))
      reset({ actionNeeded: true })
      close()
   } catch (error: any) {
      handleMessage(dataContext.setRootState, 'error', t('ValidationFailed', { Item: t('Question') }), error.error.message)
   }
}
