/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { DemandAnalysis, DemandAnalysisQuestion, DemandAnalysisQuestionStatus, DemandAnalysisStatus } from '../../../api/schemas/schema'
import { useTranslation } from 'react-i18next'
import { Button, makeStyles, shorthands, tokens, mergeClasses, Divider } from '@fluentui/react-components'
import { useSearchParams } from 'react-router-dom'
import { filterQuestionsByProcess, getCurrentAnalysisColumns } from '../../../helpers/demandAnalysisHelper'
import { Text } from '../../common/text/Text'
import { Avatar } from '../../common/avatar/Avatar'
import { BadgeAnalysisQuestion } from '../BadgeAnalysisQuestion'
import { LockClosed24Regular } from '@fluentui/react-icons'
import { ModalAnalysisQuestion } from './ModalAnalysisQuestion'
import { ModalCompleteCurrentAnalysis } from './ModalCompleteCurrentAnalysis'
import { Processfilter } from '../ProcessFilter'
import { Badge } from '../../common/badge/Badge'
import { Title } from '../../common/text/Title'
import { CustomList } from '../../common/list/CustomList'

type CurrentAnalysisProps = {
   analysis: DemandAnalysis
   setAnalysisState: React.Dispatch<
      React.SetStateAction<{
         loading: boolean
         error: Error
         item: DemandAnalysis
      }>
   >
}
const useStyles = makeStyles({
   hideInPhone: {
      '@media screen and (max-width: 640px)': {
         display: 'none',
      },
   },
   phoneListWrapper: {
      display: 'none',
      '@media screen and (max-width: 640px)': {
         display: 'flex',
         flexDirection: 'column',
         ...shorthands.gap(tokens.spacingVerticalXXL),
      },
   },

   spaceBtw: {
      display: 'flex',
      justifyContent: 'space-between',
   },
   flex: {
      display: 'flex',
      ...shorthands.gap(tokens.spacingHorizontalL),
      alignItems: 'center',
   },
   phoneListItem: {
      '& > :last-child': {
         marginTop: tokens.spacingVerticalXS,
      },
      '& p': {
         ...shorthands.margin(0, 'auto'),
      },
   },
   padding: {
      paddingLeft: tokens.spacingHorizontalXL,
      paddingRight: tokens.spacingHorizontalXL,
   },
   end: {
      justifyContent: 'end',
   },
   hidden: {
      visibility: 'hidden',
   },
   header: {
      display: 'none',
      '@media screen and (max-width: 640px)': {
         display: 'block',
         '& section': {
            display: 'flex',
            justifyContent: 'space-between',
            '& h3': {
               margin: 0,
            },
         },
      },
   },
   row: {
      '& >': {
         ':nth-child(1)': {
            width: '5%',
            minWidth: '10px',
         },
         ':nth-child(2)': {
            width: '100%',
         },
         ':nth-child(3)': {
            width: '20%',
         },
         ':nth-child(4)': {
            width: '20%',
         },
         ':nth-child(5)': {
            width: '10%',
            minWidth: '100px',
         },
      },
   },
})
export const CurrentAnalysis = ({ analysis, setAnalysisState }: CurrentAnalysisProps) => {
   const { t } = useTranslation()

   const classes = useStyles()
   const processes: string[] = [t('AllProcesses')]
   const [searchParams, setSearchParams] = useSearchParams()
   const selectedProcess = searchParams.get('process')
   const [items, setItems] = useState<DemandAnalysisQuestion[]>(filterQuestionsByProcess(selectedProcess, analysis.questions, t))
   const [analysisProcessStatus, setAnalysisProcessStatus] = useState<'NotStarted' | 'Ongoing' | 'Established'>()
   const [selectedItem, setSelectedItem] = useState<DemandAnalysisQuestion>()
   const [modalCompleteOpen, setOpenCompleteModal] = useState<boolean>(false)

   const setProcess = (process: string) => {
      searchParams.set('process', process)
      setSearchParams(searchParams)
   }
   useEffect(() => {
      if (searchParams.get('process') === null || !processes.find((x) => x === searchParams.get('process'))) {
         setProcess(processes[0])
      }
   }, [window.location.search])

   useEffect(() => {
      setItems(filterQuestionsByProcess(selectedProcess, analysis.questions, t))
   }, [selectedProcess])

   useEffect(() => {
      setAnalysisProcessStatus(
         items.every((x) => x.status === DemandAnalysisQuestionStatus.Established)
            ? 'Established'
            : items.every((x) => x.status === DemandAnalysisQuestionStatus.NotAnswered)
            ? 'NotStarted'
            : 'Ongoing'
      )
   }, [JSON.stringify(items)])
   analysis.questions
      .sort((a, b) => a.processes[0].localeCompare(b.processes[0]))
      .forEach((x) =>
         x.processes.forEach((p) => {
            if (!processes.some((c) => c === p)) {
               processes.push(p)
            }
         })
      )

   const onRowClick = (item: DemandAnalysisQuestion) => {
      setSelectedItem(item)
   }
   const completeBtnDisabled =
      items.length === 0 || !items.every((x) => x.status === DemandAnalysisQuestionStatus.Answered || x.status === DemandAnalysisQuestionStatus.Established)
   const onCompleteBtnClick = () => setOpenCompleteModal(true)
   const completeBtnVisible = analysis.status === DemandAnalysisStatus.Ongoing && !items.every((x) => x.status === DemandAnalysisQuestionStatus.Established)
   return (
      <>
         <header className={classes.header}>
            <section>
               <Title as="h3">{t('CurrentSituationAnalysis')}</Title>
               {completeBtnVisible && (
                  <Button disabled={completeBtnDisabled} appearance="transparent" icon={<LockClosed24Regular />} onClick={onCompleteBtnClick} />
               )}
            </section>

            <Divider appearance="brand" />
         </header>
         <article>
            <Processfilter setProcess={setProcess} processes={processes} title={t('CurrentSituationAnalysis')} />
            {items.length > 0 ? (
               <>
                  <div className={mergeClasses(classes.padding, classes.hideInPhone)}>
                     <div className={mergeClasses(classes.flex, classes.end)}>
                        <Text weight="bold">{t('AnalysisStatus')}</Text>
                        <Badge
                           appearance="tint"
                           color={analysisProcessStatus === 'Established' ? 'success' : analysisProcessStatus === 'NotStarted' ? 'danger' : 'warning'}
                        >
                           {t(analysisProcessStatus)}
                        </Badge>
                        <Text weight="bold">{t('AllAnswered')}</Text>
                        <Text>{`${items.filter((x) => x.status !== DemandAnalysisQuestionStatus.NotAnswered).length} (${items.length})`}</Text>
                        {completeBtnVisible && (
                           <Button disabled={completeBtnDisabled} appearance="transparent" icon={<LockClosed24Regular />} onClick={onCompleteBtnClick}>
                              {t('CompleteCurrentAnalysis')}
                           </Button>
                        )}
                     </div>
                     <CustomList onRowClick={onRowClick} rowClassName={classes.row} columns={getCurrentAnalysisColumns(t)} items={items} />
                  </div>
                  <section className={classes.phoneListWrapper}>
                     {items.map((x) => (
                        <div className={classes.phoneListItem} onClick={() => onRowClick(x)}>
                           <div className={classes.spaceBtw}>
                              <span className={classes.flex}>
                                 <Text weight="semibold">{x.number}</Text>
                                 <Text>{x.processes}</Text>
                              </span>
                              <span className={classes.flex}>
                                 <Avatar name="Lia Larsson" onlyCircle color="brand" />
                                 <BadgeAnalysisQuestion status={x.status} />
                              </span>
                           </div>
                           <Text
                              style={{
                                 display: '-webkit-box',
                                 WebkitBoxOrient: 'vertical',
                                 WebkitLineClamp: 2,
                                 overflow: 'hidden',
                                 textOverflow: 'ellipsis',
                                 whiteSpace: 'normal',
                              }}
                           >
                              {x.text}
                           </Text>
                        </div>
                     ))}
                  </section>
               </>
            ) : null}
            <ModalCompleteCurrentAnalysis
               open={modalCompleteOpen}
               close={() => setOpenCompleteModal(false)}
               setItems={setItems}
               setAnalysisState={setAnalysisState}
               items={items.filter((x) => x.status !== DemandAnalysisQuestionStatus.Established)}
            />
            <ModalAnalysisQuestion item={selectedItem} close={() => setSelectedItem(undefined)} setItems={setItems} setAnalysisState={setAnalysisState} />
         </article>
      </>
   )
}
