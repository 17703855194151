import React from 'react'
import { Button, Dropdown, makeStyles, shorthands, tokens, Option } from '@fluentui/react-components'
import { useSearchParams } from 'react-router-dom'
import { Card } from '../common/card/Card'

const useStyles = makeStyles({
   onlyPhone: {
      display: 'none',
      '@media screen and (max-width: 640px)': {
         display: 'block',
         marginBottom: tokens.spacingVerticalM,
      },
   },
   hideInPhone: {
      '@media screen and (max-width: 640px)': {
         display: 'none',
      },
   },
   filterWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      ...shorthands.gap(tokens.spacingHorizontalS),
   },
   active: {
      backgroundColor: tokens.colorNeutralBackground1Selected,
   },
})

type ProcessFilterProps = {
   setProcess: (process: string) => void
   processes: string[]
   title: string
}
export const Processfilter = ({ setProcess, processes, title }: ProcessFilterProps) => {
   const [searchParams] = useSearchParams()
   const selectedProcess = searchParams.get('process')
   const classes = useStyles()
   return (
      <>
         <Card className={classes.hideInPhone} title={title} contentClassName={classes.filterWrapper}>
            <>
               {processes.map((x) => {
                  return (
                     <Button
                        onClick={() => {
                           setProcess(x)
                        }}
                        className={selectedProcess === x ? classes.active : ''}
                     >
                        {x}
                     </Button>
                  )
               })}
            </>
         </Card>
         <Dropdown
            className={classes.onlyPhone}
            style={{ width: '100%' }}
            value={selectedProcess}
            selectedOptions={[selectedProcess]}
            onOptionSelect={(e, data) => {
               setProcess(data.optionValue)
            }}
         >
            {processes.map((x) => {
               return <Option>{x}</Option>
            })}
         </Dropdown>
      </>
   )
}
