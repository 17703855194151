import React from 'react'
import { ParentQuestionInformation } from './ParentQuestionInformation'
import { GridItem } from '../../../common/grid/GridItem'
import { Divider, Radio, RadioGroup, tokens } from '@fluentui/react-components'
import { ActionPlan, ActionPlanStatus, AnswerOptionAnalysisQuestion, DemandAnalysisQuestion } from '../../../../api/schemas/schema'
import { t } from 'i18next'
import { Text } from '../../../common/text/Text'
import { Field } from '../../../common/inputs/Field'
type EstalishActionProps = {
   actionPlan: ActionPlan
   parent: DemandAnalysisQuestion
   setUpdatedAnswer: React.Dispatch<React.SetStateAction<AnswerOptionAnalysisQuestion>>
   updatedAnswer: AnswerOptionAnalysisQuestion
}
export const EstalishAction = ({ parent, actionPlan, setUpdatedAnswer, updatedAnswer }: EstalishActionProps) => {
   return (
      <>
         <ParentQuestionInformation parent={parent} actionPlan={actionPlan} />
         <GridItem size="12/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('ImplementedActions')}
            </Text>
            <Text style={{ margin: 0, marginTop: tokens.spacingVerticalXS }}>
               {actionPlan.actionTaken ? actionPlan.implementedAction : t('NoActionsTaken')}
            </Text>
         </GridItem>
         <GridItem size="12/12">
            <Divider appearance="brand" />
         </GridItem>
         <GridItem size="12/12">
            <Field label={t('SpecifyCompliance')} required>
               <RadioGroup
                  disabled={actionPlan.status === ActionPlanStatus.Established}
                  required
                  value={updatedAnswer ? updatedAnswer.id : ''}
                  onChange={(e, data) => {
                     setUpdatedAnswer(parent.answerOptions.find((x) => x.id === data.value))
                  }}
               >
                  {parent.answerOptions.map((o) => (
                     <Radio value={o.id} label={o.text} />
                  ))}
               </RadioGroup>
            </Field>
         </GridItem>
      </>
   )
}
