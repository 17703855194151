import React from 'react'
import { Badge } from '../common/badge/Badge'
import { actionPlanStatusString } from '../../helpers/enumHelper'
import { ActionPlanStatus } from '../../api/schemas/schema'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@fluentui/react-components'
import {
   LockClosed20Regular,
   Subtract20Regular,
   ArrowClockwiseDashes20Regular,
   ArrowClockwise20Regular,
   Checkmark20Regular,
   DismissCircle20Regular,
} from '@fluentui/react-icons'

type BadgeActionPlanStatusProps = {
   status: ActionPlanStatus
}
const useStyles = makeStyles({
   hideInPhone: {
      '@media screen and (max-width: 640px)': {
         display: 'none',
      },
   },
   showOnlyPhone: {
      display: 'none',
      '@media screen and (max-width: 640px)': {
         display: 'block',
      },
   },
})
export const BadgeActionPlanStatus = ({ status }: BadgeActionPlanStatusProps) => {
   const classes = useStyles()
   const { t } = useTranslation()
   const getBgColor = (): 'brand' | 'danger' | 'important' | 'informative' | 'severe' | 'subtle' | 'success' | 'warning' => {
      switch (status) {
         case ActionPlanStatus.NotDecided:
            return 'danger'
         case ActionPlanStatus.NotStarted:
            return 'warning'
         case ActionPlanStatus.Started:
            return 'warning'
         case ActionPlanStatus.Completed:
            return 'success'
         case ActionPlanStatus.Established:
            return 'informative'
         case ActionPlanStatus.NotToBeAdressed:
            return 'subtle'
      }
   }
   const getIcon = (): JSX.Element => {
      switch (status) {
         case ActionPlanStatus.NotDecided:
            return <Subtract20Regular />
         case ActionPlanStatus.NotStarted:
            return <ArrowClockwiseDashes20Regular />
         case ActionPlanStatus.Started:
            return <ArrowClockwise20Regular />
         case ActionPlanStatus.Completed:
            return <Checkmark20Regular />
         case ActionPlanStatus.Established:
            return <LockClosed20Regular />
         case ActionPlanStatus.NotToBeAdressed:
            return <DismissCircle20Regular />
      }
   }
   return (
      <>
         <Badge className={classes.showOnlyPhone} appearance="tint" color={getBgColor()} icon={getIcon()} />
         <Badge
            className={classes.hideInPhone}
            color={getBgColor()}
            style={{
               backgroundColor: getBgColor(),
            }}
         >
            {actionPlanStatusString(status, t)}
         </Badge>
      </>
   )
}
