import React, { useContext, useEffect, useRef, useState } from 'react'
import { Layout } from './Layout'
import { useTranslation } from 'react-i18next'
import { RiskAnalysisType } from '../api/schemas/schema'
import { useNavigate, useParams } from 'react-router-dom'
import { api } from '../helpers/apiHelper'
import { handleMessage } from '../helpers/stateHelper'
import { Loader } from '../components/common/spinner/Loader'
import { FRA } from '../components/riskAnalyses/FRA/FRA'
import { IRiskAnalysisState } from '../interfaces/IRiskAnalysisState'
import { DataContext } from '../context/DataContext'

export const SelectedRiskAnalysis = () => {
   const { t } = useTranslation()
   const dataContext = useContext(DataContext)
   const { accessToken } = dataContext.state
   const navigate = useNavigate()
   const [riskAnalysisState, setRiskAnalysisState] = useState<IRiskAnalysisState>({
      loading: true,
      error: null,
      item: null,
   })
   const { item, loading } = riskAnalysisState
   const { id } = useParams()
   const prevId = useRef({})

   useEffect(() => {
      const fetchData = async () => {
         try {
            const riskAnalysis = (await api(accessToken).getRiskAnalysisById(id)).data
            setRiskAnalysisState((prev) => ({ ...prev, loading: false, item: riskAnalysis }))
         } catch (error: any) {
            //   setAnalysisState((prev) => ({ ...prev, loading: false, error: { message: error.error.message, name: error.status } }))
            navigate('/risk-management')
            handleMessage(dataContext.setRootState, 'error', t('FetchFailed'), t('CouldNotFetchItem', { Item: t('DemandAnalysis').toLowerCase() }))
         }
      }
      if (prevId.current !== id) {
         prevId.current = id
         fetchData()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [id])
   const getContent = (type: RiskAnalysisType): JSX.Element => {
      switch (type) {
         case RiskAnalysisType.FRA:
            return <FRA analysis={item} setRiskAnalysisState={setRiskAnalysisState} />

         default:
            return <></>
      }
   }
   return loading ? (
      <Layout breadCrumbItems={[]}>
         <Loader />
      </Layout>
   ) : (
      getContent(item.type)
   )
}
