import React, { useEffect, useState } from 'react'
import { ActionPlan, DemandAnalysis } from '../../../api/schemas/schema'
import { Processfilter } from '../ProcessFilter'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Text } from '../../common/text/Text'
import { filterDemandActionPlansByProcess, findQuestionParentToActionPlan, getActionPlanColumns } from '../../../helpers/actionPlanHelper'
import { makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { ModalActionPlan } from './ModalActionPlan'
import { BadgeComplianceLevel } from '../BadgeComplianceLevel'
import { BadgeActionPlanStatus } from '../BadgeActionPlanStatus'
import { CustomList } from '../../common/list/CustomList'

const useStyles = makeStyles({
   phoneListWrapper: {
      display: 'none',
      '@media screen and (max-width: 640px)': {
         display: 'flex',
         flexDirection: 'column',
         ...shorthands.gap(tokens.spacingVerticalXXL),
      },
   },
   spaceBtw: {
      display: 'flex',
      justifyContent: 'space-between',
   },
   flex: {
      display: 'flex',
      ...shorthands.gap(tokens.spacingHorizontalL),
      alignItems: 'center',
   },
   phoneListItem: {
      display: 'flex',
      flexDirection: 'column',
      ...shorthands.gap(tokens.spacingVerticalS),
      '& p': {
         ...shorthands.margin(0, 'auto'),
      },
   },
   hideInPhone: {
      '@media screen and (max-width: 640px)': {
         display: 'none',
      },
   },
   row: {
      '& >': {
         ':nth-child(1)': {
            width: '5%',
            minWidth: '10px',
         },
         ':nth-child(2)': {
            width: '20%',
            minWidth: '220px',
         },
         ':nth-child(3)': {
            width: '100%',
         },
         ':nth-child(4)': {
            width: '20%',
            minWidth: '180px',
         },
         ':nth-child(5)': {
            width: '10%',
            minWidth: '100px',
         },
      },
   },
})
type ActionPlansProps = {
   setAnalysisState: React.Dispatch<
      React.SetStateAction<{
         loading: boolean
         error: Error
         item: DemandAnalysis
      }>
   >
   analysis: DemandAnalysis
}
export const ActionPlans = ({ setAnalysisState, analysis }: ActionPlansProps) => {
   const { t } = useTranslation()
   const classes = useStyles()
   const processes: string[] = [t('AllProcesses')]
   const [searchParams, setSearchParams] = useSearchParams()
   const selectedProcess = searchParams.get('process')
   const [filteredItems, setFilteredItems] = useState<ActionPlan[]>(filterDemandActionPlansByProcess(analysis.actionPlans, analysis.questions, selectedProcess))
   const [selectedItem, setSelectedItem] = useState<ActionPlan>(undefined)

   const setProcess = (process: string) => {
      searchParams.set('process', process)
      setSearchParams(searchParams)
   }
   useEffect(() => {
      setFilteredItems(filterDemandActionPlansByProcess(analysis.actionPlans, analysis.questions, selectedProcess))
   }, [selectedProcess, JSON.stringify(analysis.actionPlans)])
   useEffect(() => {
      if (searchParams.get('process') === null || !processes.find((x) => x === searchParams.get('process'))) {
         setProcess(processes[0])
      }
   }, [window.location.search])
   analysis.actionPlans.forEach((x) => {
      analysis.questions
         .find((q) => q.id === x.demandAnalysisQuestionId)
         .processes.forEach((p) => {
            if (!processes.some((c) => c === p)) {
               processes.push(p)
            }
         })
   })
   const onRowClick = (item: ActionPlan) => {
      setSelectedItem(item)
   }
   return (
      <article>
         {analysis.actionPlans.length > 0 ? (
            <>
               <Processfilter setProcess={setProcess} processes={processes} title={t('ActionPlan')} />
               <CustomList
                  className={classes.hideInPhone}
                  columns={getActionPlanColumns(analysis.questions)}
                  items={filteredItems}
                  rowClassName={classes.row}
                  onRowClick={onRowClick}
               />
               <section className={classes.phoneListWrapper}>
                  {filteredItems.map((x) => {
                     const parent = findQuestionParentToActionPlan(analysis.questions, x)
                     return (
                        <div className={classes.phoneListItem} onClick={() => onRowClick(x)}>
                           <div className={classes.spaceBtw}>
                              <span className={classes.flex}>
                                 <Text weight="semibold">{parent.number}</Text>
                                 <Text>{parent.processes}</Text>
                              </span>
                              <span className={classes.flex}>
                                 <BadgeActionPlanStatus status={x.status} />
                              </span>
                           </div>
                           <BadgeComplianceLevel text={parent.answer.text} bgHexColor={parent.answer.colorHex} />
                           <Text
                              style={{
                                 display: '-webkit-box',
                                 WebkitBoxOrient: 'vertical',
                                 WebkitLineClamp: 2,
                                 overflow: 'hidden',
                                 textOverflow: 'ellipsis',
                                 whiteSpace: 'normal',
                              }}
                           >
                              {parent.text}
                           </Text>
                        </div>
                     )
                  })}
               </section>
            </>
         ) : analysis.questions.every((x) => x.answer && x.answer.actionRequired === false) ? (
            <Text>{t('NoActionPlansRequiredForThisDemandAnalysis')}</Text>
         ) : (
            <Text>{t('NoActionPlansCreated')}</Text>
         )}

         <ModalActionPlan
            setAnalysisState={setAnalysisState}
            parent={selectedItem && analysis.questions.find((x) => x.id === selectedItem.demandAnalysisQuestionId)}
            selectedItem={selectedItem}
            close={() => {
               setSelectedItem(undefined)
            }}
         />
      </article>
   )
}
