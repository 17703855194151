import React from 'react'
import { makeStyles, mergeClasses, shorthands } from '@fluentui/react-components'

type GridProps = {
   children?: JSX.Element | JSX.Element[]
   size: '1/12' | '2/12' | '3/12' | '4/12' | '5/12' | '6/12' | '7/12' | '8/12' | '9/12' | '10/12' | '11/12' | '12/12'
   className?: string
}
const useStyles = makeStyles({
   sm12m12l1: {
      ...shorthands.gridArea('auto', 'auto', 'auto', 'span 1'),
      '@media screen and (max-width: 1007px)': { ...shorthands.gridArea('auto', 'auto', 'auto', 'span 12') },
   },
   sm12m12l2: {
      ...shorthands.gridArea('auto', 'auto', 'auto', 'span 2'),
      '@media screen and (max-width: 1007px)': { ...shorthands.gridArea('auto', 'auto', 'auto', 'span 12') },
   },
   sm12m6l3: {
      ...shorthands.gridArea('auto', 'auto', 'auto', 'span 3'),
      '@media screen and (max-width: 1007px)': { ...shorthands.gridArea('auto', 'auto', 'auto', 'span 6') },
      '@media screen and (max-width: 640px)': { ...shorthands.gridArea('auto', 'auto', 'auto', 'span 12') },
   },
   sm12m12l4: {
      ...shorthands.gridArea('auto', 'auto', 'auto', 'span 4'),
      '@media screen and (max-width: 1007px)': { ...shorthands.gridArea('auto', 'auto', 'auto', 'span 12') },
   },
   sm12m12l5: {
      ...shorthands.gridArea('auto', 'auto', 'auto', 'span 5'),
      '@media screen and (max-width: 1007px)': { ...shorthands.gridArea('auto', 'auto', 'auto', 'span 12') },
   },
   sm12m12l6: {
      ...shorthands.gridArea('auto', 'auto', 'auto', 'span 6'),
      '@media screen and (max-width: 1007px)': { ...shorthands.gridArea('auto', 'auto', 'auto', 'span 12') },
   },
   sm12m12l7: {
      ...shorthands.gridArea('auto', 'auto', 'auto', 'span 7'),
      '@media screen and (max-width: 1007px)': { ...shorthands.gridArea('auto', 'auto', 'auto', 'span 12') },
   },
   sm12m12l8: {
      ...shorthands.gridArea('auto', 'auto', 'auto', 'span 8'),
      '@media screen and (max-width: 1007px)': { ...shorthands.gridArea('auto', 'auto', 'auto', 'span 12') },
   },
   sm12m12l9: {
      ...shorthands.gridArea('auto', 'auto', 'auto', 'span 9'),
      '@media screen and (max-width: 1007px)': { ...shorthands.gridArea('auto', 'auto', 'auto', 'span 12') },
   },
   sm12m12l10: {
      ...shorthands.gridArea('auto', 'auto', 'auto', 'span 10'),
      '@media screen and (max-width: 1007px)': { ...shorthands.gridArea('auto', 'auto', 'auto', 'span 12') },
   },
   sm12m12l11: {
      ...shorthands.gridArea('auto', 'auto', 'auto', 'span 11'),
      '@media screen and (max-width: 1007px)': { ...shorthands.gridArea('auto', 'auto', 'auto', 'span 12') },
   },
   sm12m12l12: {
      ...shorthands.gridArea('auto', 'auto', 'auto', 'span 12'),
   },
})
export const GridItem = ({ children, size, className }: GridProps): JSX.Element => {
   const getClass = () => {
      switch (size) {
         case '1/12':
            return classes.sm12m12l1
         case '2/12':
            return classes.sm12m12l2
         case '3/12':
            return classes.sm12m6l3
         case '4/12':
            return classes.sm12m12l4
         case '5/12':
            return classes.sm12m12l5
         case '6/12':
            return classes.sm12m12l6
         case '7/12':
            return classes.sm12m12l7
         case '8/12':
            return classes.sm12m12l8
         case '9/12':
            return classes.sm12m12l9
         case '10/12':
            return classes.sm12m12l10
         case '11/12':
            return classes.sm12m12l11
         case '12/12':
         default:
            return classes.sm12m12l12
      }
   }
   const classes = useStyles()
   return <section className={mergeClasses(getClass(), className)}>{children}</section>
}
