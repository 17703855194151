import { Combobox, ComboboxProps, Option } from '@fluentui/react-components'
import React, { useContext, useState } from 'react'
import { Avatar } from '../avatar/Avatar'
import { UserAccount } from '../../../api/schemas/schema'
import { Control, Controller, UseFormSetValue } from 'react-hook-form'
import { Field } from './Field'
import { DataContext } from '../../../context/DataContext'

type AccountPickerProps = {
   label: string
   name: any
   control: Control<any>
   setValue: UseFormSetValue<any>
   required?: boolean
}
export const AccountPicker = ({ label, name, control, setValue, required }: AccountPickerProps) => {
   const dataContext = useContext(DataContext)
   const { userAccounts } = dataContext.state
   const [matchingOptions, setMatchingOptions] = useState<UserAccount[]>(userAccounts)
   const [input, setInput] = useState<string>('')
   const onInput: ComboboxProps['onChange'] = (event) => {
      const value = event.target.value.trim()
      setInput(value)
      let matches = userAccounts
      if (value.length > 0) {
         matches = userAccounts.filter((option) => option.title.toLowerCase().indexOf(value.toLowerCase()) === 0)
      }
      setMatchingOptions(matches)
   }
   return (
      <Controller
         name={name}
         control={control}
         render={({ field: { value } }) => (
            <Field required={required ?? false} label={label}>
               <Combobox
                  value={value ? value.title : input}
                  selectedOptions={value ? [value.id] : []}
                  required={required ?? false}
                  freeform
                  onChange={onInput}
                  onOptionSelect={(e, data) => {
                     setValue(
                        name,
                        userAccounts.find((x) => x.id === data.optionValue)
                     )
                     setValue(`${name}Id`, data.optionValue)
                  }}
               >
                  {matchingOptions.map((account) => (
                     <Option value={account.id} text={account.title}>
                        <Avatar color="colorful" name={account.title} />
                     </Option>
                  ))}
               </Combobox>
            </Field>
         )}
      />
   )
}
