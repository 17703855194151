import React from 'react'
import { AvatarNamedColor, Avatar as FluentAvatar, Persona, makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
type AvatarProps = {
   name: string
   onlyCircle?: boolean
   color?: 'brand' | 'neutral' | 'colorful' | AvatarNamedColor
   className?: string
}
const useStyles = makeStyles({
   avatar: {
      '& > span:last-child': {
         fontSize: tokens.fontSizeBase400,
      },
   },
})
export const Avatar = ({ name, onlyCircle, color, className }: AvatarProps) => {
   const classes = useStyles()
   if (onlyCircle) return <FluentAvatar color={color ?? 'neutral'} name={name} size={20} />
   return (
      <Persona
         className={mergeClasses(classes.avatar, className)}
         textAlignment="center"
         color={color ?? 'neutral'}
         name={name}
         primaryText={name}
         avatar={{ color: color ?? 'neutral' }}
      />
   )
}
