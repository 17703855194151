import React, { useContext, useEffect, useState } from 'react'
import { Button, Dialog, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { OrganisationStep } from './OrganisationStep'
import { BranchStep } from './BranchStep'
import { ProtectedAssetsStep } from './ProtectedAssetsStep'
import { DataContext } from '../../context/DataContext'
import { DialogSurface } from '../common/modal/Modal'
import { skipStartModal } from '../../helpers/apiHelper'

const useStyles = makeStyles({
   dialogSurface: {
      backgroundSize: '100% 80px',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `linear-gradient(${tokens.colorBrandBackground} 0 0, ${tokens.colorBrandBackground} 10% 100%)`,
      ...shorthands.borderRadius(tokens.borderRadiusLarge),
   },
   dialogTitle: {
      color: '#FFDF9E',
      textAlign: 'center',
   },
   dialogContent: {
      ...shorthands.margin('50px', 0, '20px', 0),
   },
})

type StartUpWizardProps = {
   open: boolean
}

export const StartUpWizard = ({ open }: StartUpWizardProps): JSX.Element => {
   const classes = useStyles()
   const [activeStep, setActiveStep] = useState<number>(1)
   const dataContext = useContext(DataContext)
   const { userAccount, branch } = dataContext.state
   useEffect(() => {
      if (userAccount && userAccount.organisationId) {
         setActiveStep(2)
         if (branch) {
            setActiveStep(3)
         }
      }
   }, [branch, userAccount])
   const next = () => {
      setActiveStep((prev) => prev + 1)
   }
   return (
      <Dialog open={open}>
         <DialogSurface className={classes.dialogSurface}>
            {activeStep === 1 && <OrganisationStep dialogContentClass={classes.dialogContent} dialogTitleClass={classes.dialogTitle} nextStep={next} />}
            {activeStep === 2 && <BranchStep dialogContentClass={classes.dialogContent} dialogTitleClass={classes.dialogTitle} nextStep={next} />}
            {activeStep === 3 && <ProtectedAssetsStep dialogContentClass={classes.dialogContent} dialogTitleClass={classes.dialogTitle} nextStep={next} />}
            {process.env.REACT_APP_ENV === 'dev' ? <Button onClick={() => skipStartModal(dataContext)}>Skippa startmodal</Button> : null}
         </DialogSurface>
      </Dialog>
   )
}
