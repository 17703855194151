import { Input } from '@fluentui/react-components'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { Control, Controller } from 'react-hook-form'
import './resetInput.css'
import { Field } from './Field'

type ControlledMoneyInputProps = {
   name: any
   control: Control<any>
   label?: string
   disabled?: boolean
   required?: boolean
}
export const ControlledMoneyInput = ({ name, control, label, disabled, required }: ControlledMoneyInputProps) => {
   const [shownValue, setShownValue] = useState<string>('')
   const [value, setValue] = useState<number>()
   useEffect(() => {
      if (value === undefined) setShownValue('')
   }, [value])
   return (
      <Controller
         name={name}
         control={control}
         rules={{ required: required ? t('RequiredField') : false, pattern: /^[0-9]+$/ }}
         render={({ field: { value, onChange }, fieldState: { error, invalid } }) => {
            setValue(value)
            return (
               <Field label={label} required={required ?? false} validationMessage={error ? error.message : null} validationState={error ? 'error' : null}>
                  <Input
                     type="text"
                     value={shownValue}
                     onChange={(e, data) => {
                        const rawValue = data.value.replace(/\D/g, '')
                        const formattedValue = new Intl.NumberFormat('sv-SE').format(Number(rawValue))
                        setShownValue(formattedValue)
                        onChange(Number(rawValue))
                     }}
                     disabled={disabled ?? false}
                     contentAfter="kr"
                  />
               </Field>
            )
         }}
      />
   )
}
