import React from 'react'
import { Divider, makeStyles, tokens } from '@fluentui/react-components'
import { GridItem } from '../../../common/grid/GridItem'
import { Text } from '../../../common/text/Text'
import { useTranslation } from 'react-i18next'
import { ActionPlan } from '../../../../api/schemas/schema'
import { Avatar } from '../../../common/avatar/Avatar'
import { Badge } from '../../../common/badge/Badge'

type ActionPlanInfoProps = {
   actionPlan: ActionPlan
}
const useStyles = makeStyles({
   marginTopXXS: {
      marginTop: tokens.spacingVerticalXXS,
   },
   marginTopXS: {
      marginTop: tokens.spacingVerticalXS,
   },
})
export const ActionPlanInfo = ({ actionPlan }: ActionPlanInfoProps) => {
   const { t } = useTranslation()
   const classes = useStyles()
   return (
      <>
         <GridItem size="12/12">
            <Divider appearance="brand" />
         </GridItem>
         <GridItem size="4/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('Priority')}
            </Text>
            <Badge
               appearance="filled"
               color={actionPlan.priority === 1 || actionPlan.priority === 2 ? 'danger' : 'warning'}
               className={classes.marginTopXXS}
               style={{ margin: 0 }}
            >
               {actionPlan.priority}
            </Badge>
         </GridItem>
         <GridItem size="4/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('ActionDecidedBy')}
            </Text>
            <Avatar className={classes.marginTopXXS} name={actionPlan.decidedBy.title}></Avatar>
         </GridItem>
         <GridItem size="12/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('DecisionOnAction')}
            </Text>
            <Text style={{ margin: 0 }}>{actionPlan.decidedAction}</Text>
         </GridItem>
         <GridItem size="12/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('Comment')}
            </Text>
            <Text style={{ margin: 0 }}>{actionPlan.decidedActionComment}</Text>
         </GridItem>
      </>
   )
}
