import React from 'react'
import {
   BookExclamationMark24Filled,
   BookExclamationMark24Regular,
   Status24Regular,
   Status24Filled,
   ClipboardSearch24Regular,
   ClipboardSearch24Filled,
   TaskListSquareLtr24Regular,
   TaskListSquareLtr24Filled,
} from '@fluentui/react-icons'
import { Tab } from '@fluentui/react-components'
import { DemandAnalysisTabs } from '../../../helpers/demandAnalysisHelper'

type DemandAnalysisNavLinksProps = {
   id: string
   active: string
   completeDemandAnalysisTab: JSX.Element
}
export const DemandAnalysisNavLinks = ({ active, id, completeDemandAnalysisTab }: DemandAnalysisNavLinksProps) => {
   const baseLink = `/compliance/${id}`
   return (
      <>
         <Tab
            value={`${baseLink}?tab=${DemandAnalysisTabs.Info}`}
            icon={active === DemandAnalysisTabs.Info ? <BookExclamationMark24Filled /> : <BookExclamationMark24Regular />}
         />
         <Tab value={`${baseLink}?tab=${DemandAnalysisTabs.Status}`} icon={active === DemandAnalysisTabs.Status ? <Status24Filled /> : <Status24Regular />} />
         <Tab
            value={`${baseLink}?tab=${DemandAnalysisTabs.CurrentAnalysis}`}
            icon={active === DemandAnalysisTabs.CurrentAnalysis ? <ClipboardSearch24Filled /> : <ClipboardSearch24Regular />}
         />
         <Tab
            value={`${baseLink}?tab=${DemandAnalysisTabs.ActionPlan}`}
            icon={active === DemandAnalysisTabs.ActionPlan ? <TaskListSquareLtr24Filled /> : <TaskListSquareLtr24Regular />}
         />
         {completeDemandAnalysisTab}
      </>
   )
}
