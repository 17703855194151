import React, { useContext } from 'react'
import { DataContext } from './context/DataContext'
import { Spinner } from '@fluentui/react-components'
import { StartUpWizard } from './components/startupWizard/StartUpWizard'
import { Router } from './Router'

export const App = () => {
   const dataContext = useContext(DataContext)
   const { initialLoading, userAccount, branch, protectedAssets } = dataContext.state

   return (
      <>
         {initialLoading ? (
            <Spinner style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
         ) : (
            <>
               <StartUpWizard open={userAccount && (!userAccount.organisationId || !branch || protectedAssets.length === 0)} />
               {/** Open if userAccount.organisationid && branch exist with organisationid && protectedasset exists with organisationid */}
               <Router />
            </>
         )}
      </>
   )
}
