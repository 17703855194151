import React from 'react'
import { Layout } from './Layout'
import { Text } from '../components/common/text/Text'
import { useTranslation } from 'react-i18next'
import { MessageBar, MessageBarBody, MessageBarTitle } from '@fluentui/react-components'
import { BaseNavLinks } from '../components/common/navigation/BaseNavLinks'
import { Navigation } from '../components/common/navigation/Navigation'

export const Settings = () => {
   const { t } = useTranslation()
   const packageJson = require('../../package.json')
   const appVersion = packageJson.version
   return (
      <Layout breadCrumbItems={[{ path: '/settings', title: t('Settings'), active: true }]}>
         <Navigation desktopNavLinks={<BaseNavLinks active="/settings" />} activeDesktopTab="/settings" />
         <MessageBar>
            <MessageBarBody>
               <MessageBarTitle>Denna sida är ännu inte utvecklad.</MessageBarTitle>
            </MessageBarBody>
         </MessageBar>
         <Text>Version: {appVersion}</Text>
      </Layout>
   )
}
