import React from 'react'
import { Card } from '../../common/card/Card'
import { useTranslation } from 'react-i18next'
import { DemandAnalysis } from '../../../api/schemas/schema'
import { Grid } from '../../common/grid/Grid'
import { GridItem } from '../../common/grid/GridItem'
import { Text } from '../../common/text/Text'
import dayjs from 'dayjs'
import { Persona, makeStyles, tokens } from '@fluentui/react-components'
type InformationProps = {
   analysis: DemandAnalysis
}
const useStyles = makeStyles({
   infoWrapper: {
      '& > p': {
         marginTop: '0.5em',
         marginBottom: '0.5em',
      },
      '& > :last-child': {
         marginBottom: '1.5em',
         '& > span:last-child': {
            fontSize: tokens.fontSizeBase400,
         },
      },
   },
})
export const Information = ({ analysis }: InformationProps) => {
   const classes = useStyles()
   const { t } = useTranslation()
   return (
      <Card title={t('AnalysisInformation')} transparentInPhone>
         <Grid>
            <GridItem size="3/12">
               <section className={classes.infoWrapper}>
                  <Text weight="bold">{t('FirstDateOfAnalysis')}</Text>
                  {analysis.analysisStart ? <Text>{dayjs(analysis.analysisStart).format('YYYY-MM-DD')}</Text> : <Text italic>{t('NoDateSet')}</Text>}
               </section>
               <section className={classes.infoWrapper}>
                  <Text weight="bold">{t('LastDateOfAnalyis')}</Text>
                  {analysis.analysisEnd ? <Text>{dayjs(analysis.analysisEnd).format('YYYY-MM-DD')}</Text> : <Text italic>{t('NoDateSet')}</Text>}
               </section>
               <section className={classes.infoWrapper}>
                  <Text weight="bold">{t('AnalysisOwner')}</Text>
                  <Persona textAlignment="center" name={analysis.analysisOwner.title} primaryText={analysis.analysisOwner.title} />
               </section>
               <section className={classes.infoWrapper}>
                  <Text weight="bold">{t('BranchScope')}</Text>
                  <Text>{analysis.branch.name}</Text>
               </section>
               <section className={classes.infoWrapper}>
                  <Text weight="bold">{t('DataClassification')}</Text>
                  {analysis.dataClassification && analysis.dataClassification !== ' ' ? (
                     <Text>{analysis.dataClassification}</Text>
                  ) : (
                     <Text italic>{t('NoValueSet')}</Text>
                  )}
               </section>
               <section className={classes.infoWrapper}>
                  <Text weight="bold">{t('Comment')}</Text>
                  <Text>{analysis.comment}</Text>
               </section>
            </GridItem>
            <GridItem size="3/12">
               <section className={classes.infoWrapper}>
                  <Text weight="bold">{t('LeadershipPerspective')}</Text>
                  {analysis.leadershipPerspectives.map((x) => (
                     <Text key={x}>{x}</Text>
                  ))}
               </section>
            </GridItem>
            <GridItem size="3/12">
               <section className={classes.infoWrapper}>
                  <Text weight="bold">{t('Process')}</Text>
                  {analysis.processes.map((x) => (
                     <Text key={x}>{x}</Text>
                  ))}
               </section>
            </GridItem>
            <GridItem size="3/12">
               <section className={classes.infoWrapper}>
                  <Text weight="bold">{t('Subject')}</Text>
                  {analysis.subjects.map((x) => (
                     <Text key={x}>{x}</Text>
                  ))}
               </section>
            </GridItem>
         </Grid>
      </Card>
   )
}
