import React, { useState } from 'react'
import { ClipboardLink16Regular, Open16Regular, ClipboardLink16Filled, Dismiss20Regular } from '@fluentui/react-icons'
import { Button, Popover, PopoverSurface, PopoverTrigger, makeStyles, Link, tokens } from '@fluentui/react-components'
import { Text } from '../common/text/Text'
import { useTranslation } from 'react-i18next'
import { Title } from '../common/text/Title'
import { DemandAnalysisQuestion } from '../../api/schemas/schema'
import { RTFOutput } from '../common/outputs/RTFOutput'

const useStyles = makeStyles({
   hideInPhone: {
      '@media screen and (max-width: 640px)': {
         display: 'none',
      },
   },
   brand: {
      color: tokens.colorNeutralForeground2BrandSelected,
   },
   surface: {
      backgroundColor: tokens.colorBrandBackground2,
      width: '800px',
      maxWidth: '100%',
      boxSizing: 'border-box',
   },
   spaceBtw: {
      display: 'flex',
      justifyContent: 'space-between',
      '> *': {
         marginBottom: 0,
      },
   },
   size: {
      fontSize: '200px',
   },
})
type ConnectedDemandPopoverProps = {
   item: DemandAnalysisQuestion
}
export const ConnectedDemandPopover = ({ item }: ConnectedDemandPopoverProps) => {
   const { t } = useTranslation()
   const classes = useStyles()
   const [open, setOpen] = useState<boolean>(false)

   return (
      <Popover open={open} onOpenChange={(e, data) => setOpen(data.open ?? false)} size="large">
         <PopoverTrigger>
            <Button className={open ? classes.brand : ''} appearance="transparent" icon={open ? <ClipboardLink16Filled /> : <ClipboardLink16Regular />}>
               <Text size={200} style={{ margin: '0 auto' }} className={classes.hideInPhone}>
                  {t('OriginDemand')}
               </Text>
            </Button>
         </PopoverTrigger>
         <PopoverSurface className={classes.surface}>
            <header className={classes.spaceBtw}>
               <Title as="h4">{t('OriginDemandForTheQuestion')}</Title>
               <Button className={open ? classes.brand : ''} onClick={() => setOpen(false)} appearance="transparent" icon={<Dismiss20Regular />} />
            </header>
            <RTFOutput text={item.demandText} />
            <span>
               {item.demandUrl ? (
                  <>
                     <Text size={300}>
                        {t('ReadMore')}:{' '}
                        <Link href={item.demandUrl} appearance="default">
                           {item.demandUrlText} <Open16Regular />
                        </Link>
                     </Text>
                  </>
               ) : (
                  <>
                     <Text block={false} weight="semibold">
                        {t('Source')}:
                     </Text>{' '}
                     <Text block={false}>{item.demandSource}</Text>
                  </>
               )}
            </span>
         </PopoverSurface>
      </Popover>
   )
}
