import React from 'react'
import { Field as FluentField, makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import { FieldError } from 'react-hook-form'
const useStyles = makeStyles({
   field: {
      '& label': {
         fontWeight: tokens.fontWeightRegular,
      },
      '& input': {
         width: '100%',
      },
   },
})
type FieldProps = {
   children: JSX.Element
   label: string
   required?: boolean
   error?: FieldError
   validationMessage?: string
   validationState?: 'error' | 'warning' | 'success' | 'none'
   className?: string
}
export const Field = ({ children, label, required, validationMessage, validationState, className }: FieldProps) => {
   const classes = useStyles()
   return (
      <FluentField
         label={label}
         size="large"
         required={required ?? false}
         validationMessage={validationMessage}
         validationState={validationState}
         className={mergeClasses(classes.field, className ?? '')}
      >
         {children}
      </FluentField>
   )
}
