import { TFunction, t } from 'i18next'
import { ActionPlanStatus, DemandAnalysisQuestionStatus, RiskAnalysisType } from '../api/schemas/schema'
import { Option } from '@fluentui/react-components'

export const demandAnalysisQuestionStausString = (status: DemandAnalysisQuestionStatus, t: TFunction<'translation', undefined>) => {
   switch (status) {
      case DemandAnalysisQuestionStatus.NotAnswered:
         return t('NotAnswered')
      case DemandAnalysisQuestionStatus.Answered:
         return t('Answered')
      case DemandAnalysisQuestionStatus.Established:
         return t('Established')
   }
}

export const actionPlanStatusString = (status: ActionPlanStatus, t: TFunction<'translation', undefined>) => {
   switch (status) {
      case ActionPlanStatus.Completed:
         return t('Completed')
      case ActionPlanStatus.NotDecided:
         return t('NotDecided')
      case ActionPlanStatus.NotStarted:
         return t('NotStarted')
      case ActionPlanStatus.NotToBeAdressed:
         return t('NotToBeAdressed')
      case ActionPlanStatus.Started:
         return t('Started')
      case ActionPlanStatus.Established:
         return t('Established')
   }
}
export const getRiskAnalysisTypeString = (value: RiskAnalysisType): string => {
   switch (value) {
      case RiskAnalysisType.FRA:
         return t('SimplifiedRiskAnalysis')
   }
}
export const riskAnalysisTypeToOptions = () => {
   const options: JSX.Element[] = []
   for (const [key, value] of Object.entries(RiskAnalysisType)
      .filter(([key, value]) => typeof value === 'number')
      .sort(([aKey, aValue], [bKey, bValue]) =>
         getRiskAnalysisTypeString(aValue as RiskAnalysisType).localeCompare(getRiskAnalysisTypeString(bValue as RiskAnalysisType))
      )) {
      options.push(<Option value={value.toString()}>{getRiskAnalysisTypeString(value as RiskAnalysisType)}</Option>)
   }

   return options
}
