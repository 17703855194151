import React, { useContext, useState } from 'react'
import { Modal, ModalActions } from '../common/modal/Modal'
import { IRiskManagementState } from '../../interfaces/IRiskManagementState'
import { useTranslation } from 'react-i18next'
import { GridItem } from '../common/grid/GridItem'
import { Grid } from '../common/grid/Grid'
import { Button, Divider, Dropdown, makeStyles, tokens, shorthands, ProgressBar } from '@fluentui/react-components'
import { ControlledTextInput } from '../common/inputs/TextInput'
import { Controller, useForm } from 'react-hook-form'
import { RiskAnalysis } from '../../api/schemas/schema'
import { AccountPicker } from '../common/inputs/AccountPicker'
import { ControlledDatePicker } from '../common/inputs/DatePicker'
import { riskAnalysisTypeToOptions } from '../../helpers/enumHelper'
import { Field } from '../common/inputs/Field'
import { DataContext } from '../../context/DataContext'
import { addRiskAnalysis } from '../../helpers/riskManagementHelper'
import { Text } from '../common/text/Text'
import { useNavigate } from 'react-router-dom'

type ModalRiskAnalysisProps = {
   setRiskManagementState: React.Dispatch<React.SetStateAction<IRiskManagementState>>
   riskManagementState: IRiskManagementState
}
const useStyles = makeStyles({
   grid: {
      ...shorthands.margin(tokens.spacingHorizontalL, 0),
   },
   btnRight: { justifyContent: 'end' },
})
export const ModalRiskAnalysis = ({ setRiskManagementState, riskManagementState }: ModalRiskAnalysisProps) => {
   const dataContext = useContext(DataContext)
   const { t } = useTranslation()
   const navigate = useNavigate()
   const { open } = riskManagementState
   const {
      control,
      setValue,
      reset,
      getValues,
      handleSubmit,
      formState: { isSubmitting, isValid, isSubmitSuccessful },
   } = useForm<RiskAnalysis>()
   const [error, setError] = useState<string>(null)
   const classes = useStyles()
   const onSubmit = async (data: RiskAnalysis) => {
      await addRiskAnalysis(setRiskManagementState, setError, reset, dataContext, data)
   }
   const openItem = () => {
      navigate(`/risk-management/${getValues().id}`)
      reset()
   }
   return (
      <>
         <Modal open={open} title={t('NewRiskAnalysis')}>
            <form onSubmit={handleSubmit(onSubmit)}>
               <Divider appearance="brand" />
               <Grid className={classes.grid}>
                  <GridItem size="12/12">
                     <Controller
                        control={control}
                        name="type"
                        render={({ field: { onChange, value } }) => (
                           <Field label={t('TypeOfRiskAnalysis')}>
                              <Dropdown
                                 selectedOptions={value || value === 0 ? [value.toString()] : []}
                                 onOptionSelect={(e, d) => onChange(Number(d.optionValue))}
                              >
                                 {riskAnalysisTypeToOptions()}
                              </Dropdown>
                           </Field>
                        )}
                     />
                  </GridItem>
                  <GridItem size="12/12">
                     <ControlledTextInput label={t('NameOfAnalysis')} control={control} name={'name'} required />
                  </GridItem>
                  <GridItem size="12/12">
                     <ControlledTextInput label={t('Purpose/Scope')} control={control} name={'purposeScope'} required multiline />
                  </GridItem>
                  <GridItem size="12/12">
                     <ControlledTextInput label={t('Limitation')} control={control} name={'limitation'} required multiline />
                  </GridItem>
                  <GridItem size="12/12">
                     <Field label={t('DataClassification')}>
                        <Dropdown disabled />
                     </Field>
                  </GridItem>
                  <GridItem size="6/12">
                     <AccountPicker control={control} name={'analysisOwner'} label={t('RiskOwner')} setValue={setValue} required />
                  </GridItem>
                  <GridItem size="6/12">
                     <AccountPicker control={control} name={'analysisLeader'} label={t('AnalysisLeader')} setValue={setValue} />
                  </GridItem>
                  <GridItem size="6/12">
                     <ControlledDatePicker control={control} name={'startDate'} label={t('StartOfAnalysis')} />
                  </GridItem>
                  <GridItem size="6/12">
                     <ControlledDatePicker control={control} name={'endDate'} label={t('EndOfAnalysis')} />
                  </GridItem>
                  <GridItem size="12/12">
                     <ControlledTextInput control={control} name={'comment'} label={t('Comment')} multiline />
                  </GridItem>
               </Grid>
               <ModalActions>
                  <Button
                     onClick={() => {
                        reset()
                        setRiskManagementState((prev) => ({ ...prev, open: false }))
                     }}
                  >
                     {t('Cancel')}
                  </Button>
                  <Button appearance="primary" type="submit">
                     {t('CreateAnalysis')}
                  </Button>
               </ModalActions>
            </form>
         </Modal>

         <Modal
            modalType="alert"
            title={isSubmitting ? t('CreatingRiskAnalysis') : error ? t('CouldNotCreateItem', { Item: t('RiskAnalysis') }) : t('RiskAnalysisCreated')}
            open={isValid && (isSubmitting || isSubmitSuccessful)}
         >
            {isSubmitting ? (
               <>
                  <ProgressBar />
                  <Text>{t('ThisCanTakeAMoment')}</Text>
               </>
            ) : error ? (
               <Text>
                  {t('ErrorMessage')}: {error}
               </Text>
            ) : (
               <Text>{t('ClickContinueToGoToAnalysis')}</Text>
            )}
            <ModalActions className={classes.btnRight}>
               {error ? (
                  <Button onClick={() => reset()}>{t('Close')}</Button>
               ) : isSubmitSuccessful ? (
                  <Button appearance="primary" onClick={openItem}>
                     {t('Continue')}
                  </Button>
               ) : null}
            </ModalActions>
         </Modal>
      </>
   )
}
