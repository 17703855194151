import React, { useContext } from 'react'
import { Button, DialogActions, DialogContent, DialogTitle, Dropdown } from '@fluentui/react-components'
import { Branch } from '../../api/schemas/schema'
import { useForm } from 'react-hook-form'
import { DataContext } from '../../context/DataContext'
import { handleMessage } from '../../helpers/stateHelper'
import { useMsal } from '@azure/msal-react'
import { GridItem } from '../common/grid/GridItem'
import { Grid } from '../common/grid/Grid'
import { useTranslation } from 'react-i18next'
import { ControlledTextInput } from '../common/inputs/TextInput'
import { ControlledDatePicker } from '../common/inputs/DatePicker'
import { api } from '../../helpers/apiHelper'
import { ControlledMoneyInput } from '../common/inputs/ControlledMoneyInput'
import { ControlledZipInput } from '../common/inputs/ControlledZipInput'
import { DialogBody } from '../common/modal/Modal'
import { Field } from '../common/inputs/Field'

type BranchStepProps = {
   nextStep: () => void
   dialogTitleClass: string
   dialogContentClass: string
}
export const BranchStep = ({ nextStep, dialogTitleClass, dialogContentClass }: BranchStepProps) => {
   const dataContext = useContext(DataContext)
   const { setRootState } = dataContext
   const { organisation, accessToken } = dataContext.state
   const branchForm = useForm<Branch>({
      defaultValues: {
         name: 'Hela verksamheten',
         organisationSize: organisation.organisationSize,
         organisationType: organisation.organisationType,
         snIs: organisation.snIs.map((x) => ({ branchId: undefined, id: x.id, sni: x.sni, sniId: x.sniId })),
         organisationId: organisation.id,
         organisationSizeId: organisation.organisationSizeId,
         organisationTypeId: organisation.organisationTypeId,
      },
   })
   const { control, handleSubmit, watch } = branchForm
   const { accounts } = useMsal()
   const { t } = useTranslation()

   const onSubmit = async (data: Branch) => {
      try {
         data.createdBy = accounts[0].name
         data.modifiedBy = accounts[0].name
         const addedBranch: Branch = (await api(accessToken).addBranch(data)).data
         dataContext.setRootState((prev) => ({
            ...prev,
            branch: addedBranch,
         }))
         nextStep()
      } catch (error: any) {
         handleMessage(setRootState, 'error', t('SomethingWentWrong'), error.message)
      }
   }

   return (
      <form onSubmit={handleSubmit(onSubmit)}>
         <DialogBody>
            <DialogTitle action="" as="h3" className={dialogTitleClass}>
               {t('Branch')}
            </DialogTitle>
            <DialogContent className={dialogContentClass}>
               <Grid gap="L">
                  <GridItem size="12/12">
                     <ControlledTextInput required disabled control={control} name={'name'} label={t('BranchName')} />
                  </GridItem>
                  {/* <GridItem size="5/12">
                     <ControlledTextInput control={control} name={'propertyDesignation'} label={t('PropertyDesignation')} />
                  </GridItem> */}
                  <GridItem size="7/12">
                     <Field label={t('OrganisationType')}>
                        <Dropdown value={watch('organisationType.text')} disabled style={{ minWidth: 'auto' }} />
                     </Field>
                  </GridItem>
                  <GridItem size="5/12">
                     <Field label={t('OrganisationSize')}>
                        <Dropdown value={watch('organisationSize.text')} disabled style={{ minWidth: 'auto' }} />
                     </Field>
                  </GridItem>
                  <GridItem size="12/12">
                     <Field label={t('SNICodes')}>
                        <Dropdown
                           disabled
                           value={watch('snIs')
                              ?.map((x) => x.sni.code)
                              .join(', ')}
                           style={{ minWidth: 'auto' }}
                        />
                     </Field>
                  </GridItem>
                  <GridItem size="12/12">
                     <ControlledTextInput required control={control} name={'description'} label={t('BranchDescription')} multiline />
                  </GridItem>
                  <GridItem size="7/12">
                     <ControlledMoneyInput control={control} name={'valuation'} label={t('BranchValuation')} />
                  </GridItem>
                  <GridItem size="5/12">
                     <ControlledDatePicker control={control} name={'valuationDate'} label={t('DateForValuation')} />
                  </GridItem>
                  <GridItem size="7/12">
                     <ControlledTextInput required control={control} name={'responsible'} label={t('BranchResponsible')} />
                  </GridItem>

                  <GridItem size="12/12">
                     <ControlledTextInput required control={control} name={'address'} label={t('VisitingAdress')} />
                  </GridItem>
                  <GridItem size="5/12">
                     <ControlledZipInput required control={control} name={'postalCode'} label={t('PostalCode')} />
                  </GridItem>
                  <GridItem size="7/12">
                     <ControlledTextInput required control={control} name={'county'} label={t('County')} />
                  </GridItem>
                  {/* <GridItem size="12/12">
                     <ControlledTextInput control={control} name={'keyFigures'} label={t('KeyFigures')} disabled />
                  </GridItem> */}
               </Grid>
            </DialogContent>
            <DialogActions>
               <Button type={'submit'} appearance="primary">
                  {t('Next')}
               </Button>
            </DialogActions>
         </DialogBody>
      </form>
   )
}
