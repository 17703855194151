import React, { useContext } from 'react'
import {
   Home24Filled,
   Home24Regular,
   Book24Regular,
   Book24Filled,
   ShieldError24Regular,
   ShieldError24Filled,
   Settings24Filled,
   Settings24Regular,
} from '@fluentui/react-icons'
import { Tab } from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'
import { DataContext } from '../../../context/DataContext'

type BaseNavLinksProps = {
   active: '/' | '/compliance' | '/risk-management' | '/settings'
}
export const BaseNavLinks = ({ active }: BaseNavLinksProps) => {
   const data = useContext(DataContext)
   const { t } = useTranslation()
   const { navigationExpanded } = data.state
   return (
      <>
         <Tab value={'/'} icon={active === '/' ? <Home24Filled /> : <Home24Regular />}>
            {navigationExpanded && t('Dashboard')}
         </Tab>
         <Tab value={'/compliance'} icon={active === '/compliance' ? <Book24Filled /> : <Book24Regular />}>
            {navigationExpanded && t('ComplianceOfDemands')}
         </Tab>
         <Tab value={'/risk-management'} icon={active === '/risk-management' ? <ShieldError24Filled /> : <ShieldError24Regular />}>
            {navigationExpanded && t('RiskManagement')}
         </Tab>
         <Tab value={'/settings'} icon={active === '/settings' ? <Settings24Filled /> : <Settings24Regular />}>
            {navigationExpanded && t('Settings')}
         </Tab>
      </>
   )
}
