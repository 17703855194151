import React from 'react'
import { makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components'

type GridProps = {
   children: JSX.Element | JSX.Element[]
   className?: string
   gap?: 'S' | 'M' | 'L' | 'XL' | 'XXL' | 'XXXL'
}
const useStyles = makeStyles({
   grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(12, 1fr)',

      // ...shorthands.gap(tokens.spacingVerticalL, tokens.spacingVerticalL),
      // '@media screen and (min-width: 600px)': {
      //    ...shorthands.gap(tokens.spacingVerticalXXXL, tokens.spacingHorizontalL),
      // },
   },
   S: {
      ...shorthands.gap(tokens.spacingVerticalS, tokens.spacingHorizontalS),
   },
   M: {
      ...shorthands.gap(tokens.spacingVerticalM, tokens.spacingHorizontalM),
   },
   L: {
      ...shorthands.gap(tokens.spacingVerticalL, tokens.spacingHorizontalL),
   },
   XL: {
      ...shorthands.gap(tokens.spacingVerticalXL, tokens.spacingHorizontalXL),
   },
   XXL: {
      ...shorthands.gap(tokens.spacingVerticalXXL, tokens.spacingHorizontalXXL),
   },
   XXXL: {
      ...shorthands.gap(tokens.spacingVerticalXXXL, tokens.spacingHorizontalXXXL),
   },
})
export const Grid = ({ children, className, gap }: GridProps) => {
   const classes = useStyles()
   const getGap = () => {
      switch (gap) {
         case 'S':
            return classes.S
         case 'M':
            return classes.M
         case 'L':
            return classes.L
         case 'XL':
            return classes.XL
         case 'XXL':
            return classes.XXL
         case 'XXXL':
            return classes.XXXL
         default:
            return classes.XL
      }
   }
   return <article className={mergeClasses(className, getGap(), classes.grid)}>{children}</article>
}
