import React from 'react'
import { Badge } from '../common/badge/Badge'
import { tokens } from '@fluentui/react-components'

type BadgeComplianceLevelProps = {
   bgHexColor: string
   text: string
   className?: string
}
export const BadgeComplianceLevel = ({ text, bgHexColor, className }: BadgeComplianceLevelProps) => {
   return (
      <Badge
         appearance="filled"
         color="informative"
         style={{
            color: 'black',
            backgroundColor: bgHexColor ? `${bgHexColor}A6` : tokens.colorBrandBackground2,
            boxShadow: tokens.shadow8,
            fontWeight: tokens.fontWeightRegular,
            fontSize: tokens.fontSizeBase300,
         }}
         className={className ?? ''}
      >
         {text}
      </Badge>
   )
}
