import React from 'react'
import { DemandAnalysisQuestionStatus } from '../../api/schemas/schema'
import { makeStyles } from '@fluentui/react-components'
import { Text } from '../common/text/Text'
import { LockClosed20Regular, Checkmark20Regular, Subtract20Regular } from '@fluentui/react-icons'
import { demandAnalysisQuestionStausString } from '../../helpers/enumHelper'
import { useTranslation } from 'react-i18next'
import { Badge } from '../common/badge/Badge'

type BadgeAnalysisQuestionProps = {
   status: DemandAnalysisQuestionStatus
}
const useStyles = makeStyles({
   hideInPhone: {
      '@media screen and (max-width: 640px)': {
         display: 'none',
      },
   },
   showOnlyPhone: {
      display: 'none',
      '@media screen and (max-width: 640px)': {
         display: 'block',
      },
   },
})
export const BadgeAnalysisQuestion = ({ status }: BadgeAnalysisQuestionProps) => {
   const classes = useStyles()
   const { t } = useTranslation()
   return (
      <>
         <Badge
            className={classes.showOnlyPhone}
            appearance="tint"
            color={
               status === DemandAnalysisQuestionStatus.NotAnswered ? 'danger' : status === DemandAnalysisQuestionStatus.Answered ? 'success' : 'informative'
            }
            icon={
               status === DemandAnalysisQuestionStatus.NotAnswered ? (
                  <Subtract20Regular className={classes.showOnlyPhone} />
               ) : status === DemandAnalysisQuestionStatus.Answered ? (
                  <Checkmark20Regular className={classes.showOnlyPhone} />
               ) : (
                  <LockClosed20Regular className={classes.showOnlyPhone} />
               )
            }
         />
         <Badge
            className={classes.hideInPhone}
            appearance="tint"
            color={
               status === DemandAnalysisQuestionStatus.NotAnswered ? 'danger' : status === DemandAnalysisQuestionStatus.Answered ? 'success' : 'informative'
            }
         >
            <Text>{demandAnalysisQuestionStausString(status, t)}</Text>
         </Badge>
      </>
   )
}
