import React from 'react'
import { Text as FluentText } from '@fluentui/react-components'

type TextProps = {
   children: string | string[] | number | JSX.Element | JSX.Element[] | (string | JSX.Element | number)[]
   align?: 'center' | 'end' | 'justify' | 'start'
   onClick?: () => void
   block?: boolean
   className?: string
   italic?: boolean
   weight?: 'regular' | 'bold' | 'semibold'
   size?: 600 | 100 | 200 | 300 | 400 | 500 | 700 | 800 | 900 | 1000
   as?: 'p' | 'span'
   strikeThrough?: boolean
   underline?: boolean
   style?: React.CSSProperties
   truncate?: boolean
}

export const Text = ({ children, align, onClick, block, className, italic, weight, size, strikeThrough, underline, style, truncate }: TextProps) => {
   return (
      <FluentText
         weight={weight ?? 'regular'}
         className={className ?? ''}
         size={size ?? 400}
         as="p"
         align={align ?? 'start'}
         block={block === undefined || block ? true : false}
         onClick={onClick}
         italic={italic ?? false}
         strikethrough={strikeThrough ?? false}
         underline={underline ?? false}
         style={style ?? {}}
         truncate={truncate}
      >
         {children}
      </FluentText>
   )
}
